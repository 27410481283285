import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateEntity } from '../entity.service';
import { IUpdateEntityFormValues, UpdateEntityForm } from './update-entity-form.component';
import { IEntity } from '../../../../lib/types';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useCompanyDepartments } from '../../departments/department.service';
import { useCompanyLocations } from '../../locations/location.service';
import { Loader } from '../../../_core/loader.component';
import { ICloseableDialogProps } from '../../../_core/core.types';

export const UpdateEntityContainer = ({ close, ...entity }: IEntity & ICloseableDialogProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const { updateEntity } = useUpdateEntity();

  const handleUpdate = async ({ department, location, name }: IUpdateEntityFormValues) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateEntity({
        name,
        department: department.map(({ value }) => value),
        location: location.map(({ value }) => value),
        _id: entity._id
      });
      setSubmitError(null);
      setSuccess('Successfully updated company!');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to update company.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Company"
    >
      {!departments || !locations ? (
        <Loader />
      ) : (
        <UpdateEntityForm departments={departments} entity={entity} locations={locations} onSubmit={handleUpdate} />
      )}
    </FormContainer>
  );
};
