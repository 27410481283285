import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { IRenderOpenToggleParams, renderOpenToggle, renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { YesNo } from '../yes-no';
import { SECTION_WRAPPER_CLASS, Section } from '../section';
import { RadioData } from '../radio';
import { ISectionData } from '../section/section.types';
import { localTitleBlockExport, localTitleBlockImport } from '../_core/utils/block.utils';
import { ILabelBlockData } from '../_core/label-block';
import { getSectionElementIds, renderSectionTemplateHeader } from '../_core/utils/section.utils';
import { BlockTitles, BlockType } from '../_core/editor.const';

export interface IBranchData extends ILabelBlockData {
  questionData: RadioData;
  sectionsData: ISectionData[];
}

export class Branch extends LocalTitleBlockTool<IBranchData, ICreateTemplateBlockConfig> {
  private open: boolean[];
  private sections: Section[];
  private yesNo: YesNo;

  private blockWrapperId: string;
  private branchSectionIdPrefix: string;
  private selectedSection: number;

  constructor(props: BlockToolConstructorOptions<IBranchData>) {
    const { data, ...rest } = props;
    super({
      ...rest,
      data: {
        ...data,
        questionData: {
          ...data.questionData,
          options: data.questionData?.options ?? ['Yes', 'No']
        },
        sectionsData: data.sectionsData ?? [{}, {}]
      }
    });

    this.blockWrapperId = 'block-wrapper-' + this.uid;
    this.branchSectionIdPrefix = 'branch-section-' + this.uid + '-';

    // Created nested controlled block components
    this.sections = this.data.sectionsData.map((sd) => new Section({ ...rest, data: sd }));
    this.open = this.sections.map(() => true);
    this.yesNo = new YesNo({ ...rest, data: this.data.questionData });

    // Show first branch section by default in edit mode
    this.selectedSection = Math.max(
      this.data.questionData.options.findIndex((o) => o === this.data.questionData.value),
      0
    );
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Branch],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm80-24c0 32.8-19.7 61-48 73.3v87.8c18.8-10.9 40.7-17.1 64-17.1h96c35.3 0 64-28.7 64-64v-6.7C307.7 141 288 112.8 288 80c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V160c0 70.7-57.3 128-128 128H176c-35.3 0-64 28.7-64 64v6.7c28.3 12.3 48 40.5 48 73.3c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-32.8 19.7-61 48-73.3V352 153.3C19.7 141 0 112.8 0 80C0 35.8 35.8 0 80 0s80 35.8 80 80zm232 0a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM80 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>'
    };
  }

  private onOpenToggle({ index, renderParams }: { index: number; renderParams: IRenderOpenToggleParams }) {
    this.open[index] = !this.open[index];

    const ids = getSectionElementIds(this.branchSectionIdPrefix, index);
    document.getElementById(ids.element)?.classList.toggle('hidden');
    renderOpenToggle({
      ...renderParams,
      open: this.open[index],
      replaceId: ids.titleWrapper,
      onOpenToggle: () => this.onOpenToggle({ index, renderParams })
    });
  }

  private renderSection({
    applyToDocumentImmediately,
    sectionIndex = this.selectedSection
  }: {
    applyToDocumentImmediately?: boolean;
    sectionIndex?: number;
  }) {
    // Destroy old section editors, so new instances are created properly on demand
    this.sections.forEach((s) => {
      s.editor.current?.destroy();
      s.editor.current = undefined;
    });

    if (sectionIndex >= 0) {
      // Create new section content and treat as always open
      const ids = getSectionElementIds(this.branchSectionIdPrefix, sectionIndex);
      const sectionElement = this.sections[sectionIndex].renderSectionContent();
      sectionElement.id = ids.element;
      sectionElement.classList.remove('hidden');

      // Return the section element unless it should be immediately inserted into the document (ie. on section re-render)
      if (applyToDocumentImmediately) {
        const blockWrapper = document.getElementById(this.blockWrapperId);
        blockWrapper?.querySelector('.' + SECTION_WRAPPER_CLASS)?.remove();
        blockWrapper?.appendChild(sectionElement);
      } else if (this.api.readOnly.isEnabled) {
        return sectionElement;
      } else {
        return renderSectionTemplateHeader({
          idPrefix: this.branchSectionIdPrefix,
          onOpenToggle: (p) => this.onOpenToggle(p),
          open: this.open[sectionIndex],
          sectionElement,
          sectionIndex,
          sectionTitleText: `${sectionIndex + 1} - ${this.data.questionData.options[sectionIndex]}`
        });
      }
    }
  }

  private handleBranchAnswer(answer: string | null) {
    if (this.data.questionData.value !== answer) {
      // Update answer and re-render selected section
      this.data.questionData.value = answer;
      this.selectedSection = this.data.questionData.options.findIndex((o) => o === this.data.questionData.value);
      this.renderSection({ applyToDocumentImmediately: true, sectionIndex: this.selectedSection });

      // Mark section as answered
      // TODO: Possibly determine required success from whole picture of required content in branch. A similar functionality might be needed section blocks.
      this.data.filled = !!answer;
      this.toggleRequiredIndicator({ checked: this.data.filled });
    }
  }

  render() {
    // Setup block wrapper with label line, and section content beneath
    this.renderWithLocalTitle(this.api.readOnly ? undefined : { maxWidth: null, readOnly: { elementType: 'span' } });

    if (!this.wrapper) this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'rounded');

    const blockWrapper = document.createElement('div');
    blockWrapper.id = this.blockWrapperId;

    // Render higher config (ie. Radio input)
    const configContainer = document.createElement('div');
    configContainer.classList.add('flex', 'gap-4', 'mt-2', 'w-full');

    const radioElement = this.yesNo.renderRadioInput({
      targetWrapper: this.wrapper,
      canEditOptions: false,
      afterChange: (v) => this.handleBranchAnswer(v)
    });
    if (radioElement) this.wrapper.appendChild(radioElement);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    // Add elements in block order
    blockWrapper.appendChild(this.wrapper);
    blockWrapper.appendChild(configContainer);

    // Show all sections in edit mode, or if an answer is selected in client mode
    if (this.data.questionData.value && this.api.readOnly.isEnabled) {
      const sectionContent = this.renderSection({});
      if (sectionContent) blockWrapper.appendChild(sectionContent);
    } else if (!this.api.readOnly.isEnabled) {
      const sectionContent: (string | Node)[] = [];
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.renderSection({ sectionIndex: i });
        if (section) sectionContent.push(section);
      }

      blockWrapper.append(...sectionContent);
    }

    return renderWithBlockFocusWrapper(blockWrapper);
  }

  async save(blockContent: HTMLDivElement): Promise<IBranchData> {
    // Save data
    const newData = await super.save(blockContent);
    newData.questionData = await this.yesNo.save(blockContent);
    newData.sectionsData = await Promise.all(this.sections.map((s) => s.save(blockContent)));

    // Update local data and data for each section instance
    this.data = newData;
    this.sections.forEach((s, i) => s.updateData(this.data.sectionsData[i]));

    return newData;
  }

  static get conversionConfig() {
    return { export: localTitleBlockExport, import: localTitleBlockImport };
  }
}
