import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { COMPLETE_ONBOARDING_KEY } from '../../../lib/const';
import { sleep } from '../../../lib/utils';

export const useLogout = () => {
  const { logout } = useAuth0();
  const [loading, setLoading] = useState(false);

  const handleLogout = useCallback(
    async (params: { onFinish?: () => void; afterExecution?: () => void } = {}) => {
      setLoading(true);
      const calledLogout = logout(params.onFinish ? undefined : { logoutParams: { returnTo: window.origin } }).finally(
        () => {
          console.log('Move to onfinish logout'); // For some reason this onFinish progression will fail unless we have a log here
          setLoading(false);
          toast.dismiss(COMPLETE_ONBOARDING_KEY);
          if (params.onFinish) return sleep(250).then(() => params.onFinish?.());
        }
      );
      params.afterExecution?.();
      return params.onFinish ? calledLogout : null;
    },
    [logout, setLoading]
  );

  return { handleLogout, loading };
};
