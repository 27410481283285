import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import {
  CreateClientInviteNestedContainer,
  ICreateClientInviteContainerProps
} from './create-client-invite-form-nested.container';
import { ICloseableDialogProps } from '../../../_core/core.types';

export const CreateClientInviteContainer = ({
  ...props
}: ICreateClientInviteContainerProps & ICloseableDialogProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Create Contact"
    >
      <CreateClientInviteNestedContainer
        onBack={props.close}
        onError={(e) => setSubmitError(e ?? null)}
        onSuccess={(s) => setSuccess(s ?? null)}
        {...props}
      />
    </FormContainer>
  );
};
