import { gql } from '@apollo/client';

export const USER_SETTINGS_FRAGMENT = gql`
  fragment UserSettings on User {
    settings {
      activeCompanyId
    }
  }
`;

export const USER_OVERVIEW_FRAGMENT = gql`
  fragment UserOverview on User {
    _id
    email
    phone
    name
    deletedAt
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserDetails on User {
    _id
    authId
    email
    phone
    isAdmin
    name
    deletedAt
    ...UserSettings
  }

  ${USER_SETTINGS_FRAGMENT}
`;

export const GET_CURR_USER = gql`
  query GetCurrUser {
    loggedInUser {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;

export const REGISTER_USER = gql`
  mutation Register($createPersonalCompany: Boolean!, $authId: String!) {
    register(createPersonalCompany: $createPersonalCompany, authId: $authId) {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;

export const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser {
      _id
    }
  }
`;

export const SET_ACTIVE_COMPANY = gql`
  mutation SetActiveCompany($companyId: String!) {
    setActiveCompany(companyId: $companyId) {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($name: String, $email: String, $phone: String) {
    updateUser(name: $name, email: $email, phone: $phone) {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;
