import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IUpdateUserParams } from '../user.types';

interface IUpdateUserFormValues {
  name: string;
}

interface IRIFormProps {
  name?: string | null;
  onSubmit: (props: IUpdateUserParams) => Promise<void>;
}

export const UpdateUserForm = ({ name, onSubmit }: IRIFormProps) => {
  const validateForm = (values: IUpdateUserFormValues) => {
    const errors: FormikErrors<IUpdateUserFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: name ?? '' }}
      onSubmit={(values) => onSubmit({ name: values.name.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IUpdateUserFormValues>) => (
        <Form className="space-y-4" action="#" method="POST">
          <FormInputWithLabel id="name" name="name" label="Name" value={props.values.name} />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
