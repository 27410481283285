import { InputType } from './input.type';

const defaultTypeClasses = 'w-full py-1.5 text-gray-900 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600';

export const getInputClasses = ({
  containerClass,
  hasError,
  type
}: {
  containerClass?: string;
  hasError?: boolean;
  type: InputType;
}) => {
  const fieldStateClasses = hasError
    ? 'ring-red-300 placeholder:text-red-400'
    : 'ring-gray-300 placeholder:text-gray-400';

  let typeClasses = '';
  switch (type) {
    case 'checkbox':
      typeClasses = 'text-primary';
      break;
    case 'textarea':
      typeClasses = `${defaultTypeClasses} min-h-48`;
      break;
    default:
      typeClasses = defaultTypeClasses;
  }

  const isCheckbox = type === 'checkbox';
  const finalContainerClass = containerClass ?? (isCheckbox ? undefined : 'w-full');
  const inputClasses = `${typeClasses} block rounded-md border-0 shadow-sm ring-1 sm:text-sm sm:leading-6 disabled:text-gray-400 ${fieldStateClasses}`;

  return { finalContainerClass, inputClasses };
};
