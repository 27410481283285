import { useCurrMember } from '../../domains/member/member.service';
import { TemplateTableContainer } from '../DashboardPage/template-table/template-table.container';

export const TemplatesPage = () => {
  const { isAdmin } = useCurrMember();

  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <TemplateTableContainer isAdmin={isAdmin} />
    </div>
  );
};
