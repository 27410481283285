import { IMultiSelectOption } from './components/_core/input/multiselect-input.component';
import { ROLE, ROLE_LABELS } from './lib/types';

export const APP_TITLE = 'Request IQ';
export const SUPPORT_EMAIL = 'support@requestiq.ca';

export const ROLE_OPTIONS: IMultiSelectOption[] = [ROLE.COMPANY_OWNER, ROLE.COMPANY_ADMIN, ROLE.COMPANY_STAFF].map(
  (value) => ({
    disabled: value === ROLE.COMPANY_OWNER,
    label: ROLE_LABELS[value],
    value
  })
);

export const BASIC_ROLE_OPTIONS = ROLE_OPTIONS.filter((r) => r.value !== ROLE.COMPANY_OWNER);

export const ROUTE_ROLES = {
  PUBLIC: 'PUBLIC',
  AUTHORIZED: 'AUTHORIZED',
  ...Object.entries(ROLE)
};
