import { FormLabel } from '../form-label.component';
import { FormInput, IFormInputProps } from './form-input.component';

interface IFormInputWithLabelProps extends IFormInputProps {
  autoFocus?: boolean;
  direction?: 'row' | 'column';
  label: string;
}

export const FormInputWithLabel = ({ label, direction, ...inputProps }: IFormInputWithLabelProps) => {
  let typeClasses = '';
  switch (inputProps.type) {
    case 'checkbox':
      typeClasses = 'items-center';
      break;
    default:
      typeClasses = 'items-start justify-between';
  }

  return (
    <div className={`flex flex-${direction ? direction : 'col'} ${typeClasses}`}>
      {inputProps.type !== 'checkbox' && <FormLabel label={label} name={inputProps.name} />}
      <FormInput {...inputProps} />
      {inputProps.type === 'checkbox' && (
        <div className="ml-4">
          <FormLabel label={label} name={inputProps.name} />
        </div>
      )}
    </div>
  );
};
