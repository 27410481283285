import { useMemo, useRef } from 'react';
import EditorJs from '@editorjs/editorjs';
import { useSaveEditor } from '../../_editor/_core/utils/editor.utils';
import { RequestPage } from './request-page.component';
import { IRequest, REQUEST_STATUS } from '../../../lib/types';

interface IRequestPageContainer {
  clientMode?: boolean;
  request: IRequest;
  token?: string;
}

export const RequestPageContainer = ({ clientMode, request, token }: IRequestPageContainer) => {
  const ref = useRef<EditorJs>();

  const canSave = useMemo(
    () =>
      !request.deletedAt &&
      (!request.status || ![REQUEST_STATUS.ARCHIVED, REQUEST_STATUS.CLOSED].includes(request.status)),
    [request.deletedAt, request.status]
  );

  const { hasChanged, onSave, saving } = useSaveEditor({
    canSave,
    ref,
    request,
    token
  });

  return (
    <RequestPage
      canSave={canSave}
      hasChanged={hasChanged}
      onSave={onSave}
      editorRef={ref}
      readOnly={clientMode}
      request={request}
      saving={saving}
      token={token}
    />
  );
};
