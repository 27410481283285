import { HighlightText } from '../typography';
import { TableHeader } from './table-header.component';
import { TableRow } from './table-row.component';
import { ITableCellProps, ITableRowClassConfig, ITableSelectableProps } from './table.types';

export interface ITableRowValues {
  rowId?: string;
  values: ITableCellProps[];
}

export interface ITableProps extends ITableSelectableProps {
  headers: React.ReactNode[];
  onRowClick?: (_: number) => void;
  onSelect?: (_index: number, _selected: boolean) => void;
  onSelectAll?: (_: boolean) => void;
  rowClasses?: (ITableRowClassConfig | null)[] | null;
  selected?: boolean[];
  searchable?: boolean; // TODO: Need to implement search functionality
  values: ITableRowValues[];
}

export const Table = ({
  headers,
  onRowClick,
  onSelect,
  onSelectAll,
  rowClasses,
  searchable,
  selected,
  selectable,
  values
}: ITableProps) => {
  return (
    <>
      <div className="relative shadow-md sm:rounded-lg w-full flex-1">
        {searchable && (
          <div className="pb-4 bg-white p-2">
            <label htmlFor="table-search" className="sr-only">
              Search
            </label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="table-search"
                className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Search htmlFor items"
              />
            </div>
          </div>
        )}
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 table-fixed">
          <TableHeader
            rowClasses={rowClasses}
            selected={!selected?.some((s) => !s)}
            selectable={selectable}
            onSelect={onSelectAll}
            values={headers.map((children) => ({ children }))}
          />
          <tbody>
            {values.length ? (
              values.map((row, i) => (
                <TableRow
                  onClick={onRowClick ? () => onRowClick(i) : undefined}
                  onSelect={onSelect ? (selected) => onSelect(i, selected) : undefined}
                  rowClasses={rowClasses}
                  selected={selected && i < selected.length && selected[i]}
                  selectable={selectable}
                  values={row.values}
                  key={'table-row-' + i}
                />
              ))
            ) : (
              <tr>
                <td className="p-4">
                  <HighlightText className="whitespace-nowrap">No data available...</HighlightText>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
