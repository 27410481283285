import React, { useMemo } from 'react';
import Select, { CSSObjectWithLabel, MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { useFormFieldMeta } from '../form-field-meta';
import { IFormInputProps } from './form-input.component';
import { IMultiSelectInputProps, IMultiSelectOption } from '../../input/multiselect-input.component';
import { reactSelectOptionClasses } from '../../input/input-consts';

interface IMultiSelectInputHiddenProps {
  error?: React.ReactNode;
}

export const FormMultiSelectInput = ({
  canAdd,
  name,
  onAdd,
  options,
  placeholder,
  singleSelect,
  skipSort,
  groupOrder,
  error
}: IMultiSelectInputProps & IFormInputProps & IMultiSelectInputHiddenProps) => {
  const {
    value: formValue,
    helpers: { setValue: setFormValue }
  } = useFormFieldMeta<IMultiSelectOption[]>(name);

  const sortOp = (a: IMultiSelectOption, b: IMultiSelectOption) => {
    const aTitle = a.label ?? a.secondaryLabel ?? a.value;
    const bTitle = b.label ?? b.secondaryLabel ?? b.value;
    return aTitle.localeCompare(bTitle);
  };

  // Transform options into groups for react-select
  const transformedOptions = useMemo(() => {
    if (groupOrder) {
      // Create group entries
      const groups: { options: IMultiSelectOption[]; label: string }[] = groupOrder.map((label) => ({
        label,
        options: []
      }));

      // Fill group entries
      options?.forEach((o) => {
        if (o.group) {
          const groupIndex = groups.findIndex((g) => g.label === o.group);
          groups[groupIndex].options.push(o);
        }
      });

      // Return groups only if some groups have data
      const nonEmptyGroups = groups.filter((g) => g.options.length);
      if (nonEmptyGroups.length) return nonEmptyGroups.map((g) => ({ ...g, options: g.options.sort(sortOp) }));
    }

    if (skipSort) return options;
    return options?.sort(sortOp);
  }, [groupOrder, options, skipSort]);

  const props = {
    isClearable: true,
    isSearchable: true,
    isMulti: !singleSelect,
    closeMenuOnSelect: !singleSelect,
    onChange: (v: MultiValue<IMultiSelectOption> | SingleValue<IMultiSelectOption>) => {
      setFormValue(Array.isArray(v) ? [...v] : [v]);
    },
    options: transformedOptions,
    value: formValue,
    placeholder: placeholder,
    styles: { placeholder: (base: CSSObjectWithLabel) => ({ ...base, fontWeight: 300, fontSize: '0.875rem' }) },
    classNames: {
      control: () => `${error ? '!border-red-300' : ''} !rounded-md`,
      option: () => reactSelectOptionClasses,
      placeholder: error ? () => '!text-red-400' : undefined
    },
    formatOptionLabel: (o: IMultiSelectOption) => (
      <div>
        <span>{o.label}</span>
        {!!o.secondaryLabel && <span className="text-xs"> ({o.secondaryLabel})</span>}
      </div>
    ),
    isOptionDisabled: (o: IMultiSelectOption) => !!o.disabled,
    filterOption: (o: { data: IMultiSelectOption }, input: string) => {
      if (!input) return true;
      return (
        !!o.data.label?.includes(input) || !!o.data.value.includes(input) || !!o.data.secondaryLabel?.includes(input)
      );
    }
  };

  return (
    <>
      {canAdd ? (
        <Creatable {...props} onCreateOption={onAdd ? (n: string) => onAdd(n) : undefined} />
      ) : (
        <Select {...props} />
      )}
      {error}
    </>
  );
};
