import { Outlet, useNavigate } from 'react-router-dom';

// Auth0
import { Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';

export const RIAuth0Provider = ({ children }: PropsWithChildren) => {
  const history = useNavigate();
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN || ''}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID || ''}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{ redirect_uri: window.origin, audience: import.meta.env.VITE_AUTH0_AUDIENCE }}
      onRedirectCallback={(appState) => {
        history(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export const AuthProvider = () => {
  return (
    <RIAuth0Provider>
      <Outlet />
    </RIAuth0Provider>
  );
};
