import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useRestoreTemplate } from '../../../../domains/template/template.service';
import { ITemplateBaseDetails } from '../../../../../lib/types';
import { ITableMultiselectActionProps } from '../../../../_core/table/table.types';

export const useRestoreTemplateAction = ({
  getSelectedModels,
  clearSelected
}: ITableMultiselectActionProps<ITemplateBaseDetails>): IButtonDropdownOptionProps => {
  const { restoreTemplate } = useRestoreTemplate();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else if (!selected.some(({ deletedAt }) => deletedAt)) toast.error('No selected templates can be restored.');
      else {
        selected.forEach((template) =>
          restoreTemplate(template).catch((err: Error) =>
            showError('Unable to restore template ' + template.title, err)
          )
        );
        clearSelected();
      }
    },
    text: 'Restore'
  };
};
