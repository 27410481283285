import { useMutation, useQuery } from '@apollo/client';

import { GET_MEMBERS, REMOVE_MEMBER, REMOVE_SELF, RESTORE_MEMBER, UPDATE_MEMBERS } from './member.queries';
import { IMembersResults } from './member.types';
import { IMember, IMemberOverview, Identifiable, ROLE } from '../../../lib/types';
import { useCallback, useMemo } from 'react';
import { IUpdateMemberParams } from '../../../lib/query.types';
import { updateMemberCache } from './member.utils';
import { useFetchCurrUser } from '../users/user.service';
import { USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';
import { GET_CURR_USER } from '../users/user.queries';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';

export const useCompanyMembers = () => {
  const { data, ...rest } = useQuery<IMembersResults>(GET_MEMBERS, { fetchPolicy: 'cache-first' });
  return { ...rest, ...data };
};

export const useCurrMember = () => {
  const { members } = useCompanyMembers();
  const { user } = useFetchCurrUser();

  const currMember = useMemo(() => members?.find((member) => member.user._id === user?._id), [members, user?._id]);

  return { currMember, isAdmin: currMember?.roles.some((r) => [ROLE.COMPANY_ADMIN, ROLE.COMPANY_OWNER].includes(r)) };
};

export const useRemoveMember = () => {
  const [mutation, rest] = useMutation<{ removeMember: IMemberOverview }, Identifiable>(REMOVE_MEMBER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateMemberCache({ cache, members: [data.removeMember] });
    }
  });

  const removeMember = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { removeMember, ...rest };
};

export const useRemoveSelf = () => {
  const navigate = useNavigate();

  const [mutation, rest] = useMutation<{ removeSelf: IMember }>(REMOVE_SELF, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) {
        // Remove member from cache and update curr user with new user
        updateMemberCache({ cache, members: [data.removeSelf] });
        cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.removeSelf.user } });
        toast.success('You have successfully removed yourself from the organization');

        // If no new active company, then the user has no available companies so let's wipe the curr company cache and navigate to the dashboard
        // TODO: Otherwise the firm profile page won't get the updated null company result (until we revisit the page). Can we force the query to use the updated company result?
        if (!data.removeSelf.user.settings.activeCompanyId) {
          if (cache.evict({ id: 'ROOT_QUERY', fieldName: 'company' })) cache.gc();
          navigate(ROUTE_PATHS.DASHBOARD);
        }
      }
    },
    // Refetch curr user and all curr company members because we will have deleted current member and connection to curr company
    refetchQueries: USER_COMPANIES_REFETCH_QUERIES
  });

  return { removeSelf: mutation, ...rest };
};

export const useRestoreMember = () => {
  const [mutation, rest] = useMutation<{ restoreMember: IMemberOverview }, Identifiable>(RESTORE_MEMBER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateMemberCache({ cache, members: [data.restoreMember] });
    }
  });

  const restoreMember = useCallback((variables: Identifiable) => mutation({ variables }), [mutation]);

  return { restoreMember, ...rest };
};

export const useUpdateMembers = () => {
  const [mutation, rest] = useMutation<{ updateMembers: IMemberOverview[] }, IUpdateMemberParams>(UPDATE_MEMBERS, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateMemberCache({ cache, members: data.updateMembers });
    }
  });

  const updateMembers = useCallback((variables: IUpdateMemberParams) => mutation({ variables }), [mutation]);

  return { updateMembers, ...rest };
};
