import { IAsset, IRequestTokenParams } from '../../../lib/types';
import { showError } from '../../../lib/utils';
import { useUploadAsset } from '../../domains/assets/asset.service';
import { Button } from '../../_core/button/button.component';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import { useRef } from 'react';
import { EditorSaveResult } from '../_core/types';

interface IUploadInput extends IRequestTokenParams {
  disabled?: boolean;
  onSave: () => Promise<EditorSaveResult>;
  onUploaded: (_: IAsset) => void;
  uid: string;
}

export const UploadInput = ({ disabled, onSave, onUploaded, uid, requestToken }: IUploadInput) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const { uploadAsset } = useUploadAsset(requestToken);

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    onAddFiles(e.dataTransfer?.files);
  };

  const onAddFiles = (files?: FileList | null) => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        uploadAsset(files[i])
          .then(({ data }) => {
            onSave();
            onUploaded(data.asset);
          })
          .catch((err) => showError('Failed to upload file.', err));
      }
    }
  };

  const inputId = 'upload-input-' + uid;
  return (
    <label
      htmlFor={inputId}
      className={`flex flex-col items-center justify-center w-full ${disabled ? '' : 'cursor-pointer'}`}
      onDrop={(e) => onDrop(e.nativeEvent)}
      onDrag={(e) => e.preventDefault()}
    >
      <Button
        id={inputId}
        icon={<PaperClipIcon width={16} height={16} className="mr-2" />}
        size="xl"
        text="Click to upload"
        type="button"
        variant="gray"
        onClick={() => ref.current?.click()}
        disabled={disabled}
      />
      <input
        accept="application/msword, .xls, .csv, .txt, .pdf, image/*, .heic, .xlsx, .numbers, .pages, .doc, .docx"
        multiple
        type="file"
        className="hidden"
        id={inputId}
        onChange={(e) => onAddFiles(e.target.files)}
        disabled={disabled}
        ref={ref}
      />
    </label>
  );
};
