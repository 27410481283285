import { SetStateAction } from 'react';
import { sleep } from '../../../lib/utils';
import { IMultiSelectOption } from '../input/multiselect-input.component';

export const extractFormOptionValue = (option: IMultiSelectOption) => option.value;

type SetNullStringAction = React.Dispatch<SetStateAction<string | null>> | ((_?: string | null) => void);

interface IResetFormMessagesParams {
  setSuccess?: SetNullStringAction;
  setSubmitError?: SetNullStringAction;
  timeout?: number;
}

export const resetFormMessages = async ({ setSuccess, setSubmitError, timeout = 100 }: IResetFormMessagesParams) => {
  // Clear any previous messages, and wait a bit to allow for a smoother transition between form responses
  setSuccess?.(null);
  setSubmitError?.(null);
  await sleep(timeout);
};
