import { Loader } from '../../_core/loader.component';
import { IClient, IEntityMember, Identifiable } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { Tabs } from '../../_core/tabs.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { EntityTableNestedContainer } from '../DashboardPage/entity-table/entity-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { useMemo, useState } from 'react';
import { UpdateClientContainer } from '../../domains/clients/update/update-client-form.container';
import { ProfileDetailsBox } from '../../_core/details/profile-details-box.component';
import { DetailItemProps } from '../../_core/details/details.types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';

interface IClientProfilePageProps extends IAdminProps, IPersonalCompanyProps {
  deleteClient: (_: Identifiable) => void;
  deleting: boolean;
  client?: IClient;
  members?: IEntityMember[];
  restoreClient: (_: Identifiable) => void;
  restoring: boolean;
}

export const ClientProfilePage: React.FC<IClientProfilePageProps> = ({
  deleteClient,
  deleting,
  client,
  isAdmin,
  isPersonalCompany,
  members,
  restoreClient,
  restoring
}) => {
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);

  const clientName = client?.name ?? client?.user.name;
  const detailItems = useMemo(() => {
    const items: DetailItemProps[] = [
      { label: 'Contact', value: clientName, placeholder: 'None' },
      { label: 'Email', value: client?.user.email }
    ];

    if (!isPersonalCompany)
      items.push(
        { label: 'Departments', placeholder: 'None', value: client?.department.map(({ name }) => name).join(', ') },
        { label: 'Locations', placeholder: 'None', value: client?.location.map(({ name }) => name).join(', ') }
      );

    items.push({ label: 'Status', value: activeStatus(!client?.deletedAt) });

    return items;
  }, [client, clientName, isPersonalCompany]);

  if (!client)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <ProfileDetailsBox
        actionItems={
          !client.deletedAt || isAdmin
            ? [
                <Button size="xl" text="Edit Contact" onClick={() => setEditing(true)} />,
                <Button
                  size="xl"
                  text={`${client.deletedAt ? 'Una' : 'A'}rchive Contact`}
                  onClick={() => {
                    if (client.deletedAt) restoreClient(client);
                    else deleteClient(client);
                  }}
                  loading={client.deletedAt ? restoring : deleting}
                />
              ]
            : undefined
        }
        detailItems={detailItems}
        title="Contact Information"
        onBack={() => navigate(ROUTE_PATHS.CONTACTS)}
      />
      <div className="mt-4 flex flex-1 w-full">
        <Tabs
          tabs={[
            {
              title: 'Associated Companies',
              content: !members ? (
                <Loader />
              ) : (
                <EntityTableNestedContainer
                  bordered
                  clientModeConfig={{ client, members }}
                  isAdmin={isAdmin}
                  entities={members.filter(({ deletedAt }) => !deletedAt).map(({ entity }) => entity)}
                />
              )
            }
          ]}
        />
      </div>
      <RIDialog open={editing} setOpen={setEditing}>
        <UpdateClientContainer client={client} close={() => setEditing(false)} />
      </RIDialog>
    </>
  );
};
