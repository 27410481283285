import { useCallback } from 'react';
import { FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ITemplate, ITemplateBaseDetails, Identifiable, TEMPLATE_TYPE } from '../../../lib/types';
import {
  CREATE_GLOBAL_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_GLOBAL_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE,
  GET_TEMPLATES,
  RESTORE_TEMPLATE,
  UPDATE_GLOBAL_TEMPLATE,
  UPDATE_TEMPLATE
} from './template.queries';
import { ICreateTemplateProps, IGetTemplatesParams, ITemplateResults, IUpdateTemplateProps } from './template.types';
import { updateTemplateInCache } from './template.utils';

export const useLazyTemplate = () => {
  const [getTemplate, { data, ...rest }] = useLazyQuery<{ template: ITemplate }, Identifiable>(GET_TEMPLATE, {
    fetchPolicy: 'cache-first'
  });

  return { getTemplate, ...rest, ...data };
};

export const useTemplate = (_id: string) => {
  const { data, ...rest } = useQuery<{ template: ITemplate }, Identifiable>(GET_TEMPLATE, {
    fetchPolicy: 'cache-first',
    variables: { _id }
  });

  return { ...rest, ...data };
};

export const useTemplates = (types: TEMPLATE_TYPE[]) => {
  const { data, ...rest } = useQuery<ITemplateResults, IGetTemplatesParams>(GET_TEMPLATES, {
    fetchPolicy: 'cache-first',
    variables: { types }
  });

  return { ...rest, ...data };
};

export const useCreateTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ createTemplate: ITemplateBaseDetails }>(CREATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.createTemplate, type });
    }
  });

  const [globalMutation, globalRest] = useMutation<{ createGlobalTemplate: ITemplateBaseDetails }>(
    CREATE_GLOBAL_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) updateTemplateInCache({ cache, template: data.createGlobalTemplate, type });
      }
    }
  );

  const createTemplate = useCallback(
    (
      variables: ICreateTemplateProps
    ): Promise<
      FetchResult<
        | {
            createTemplate?: ITemplateBaseDetails;
            createGlobalTemplate?: ITemplateBaseDetails;
          }
        | null
        | undefined
      >
    > => {
      if (variables.isGlobal) return globalMutation({ variables: { ...variables, type } });
      else return mutation({ variables: { ...variables, type } });
    },
    [globalMutation, mutation, type]
  );

  return {
    createTemplate,
    loading: rest.loading || globalRest.loading
  };
};

export const useDeleteTemplate = () => {
  const [mutation, rest] = useMutation<{ deleteTemplate: ITemplateBaseDetails }, ITemplateBaseDetails>(
    DELETE_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data && variables) updateTemplateInCache({ cache, template: data.deleteTemplate, type: variables.type });
      }
    }
  );

  const [globalMutation, globalRest] = useMutation<
    { deleteGlobalTemplate: ITemplateBaseDetails },
    ITemplateBaseDetails
  >(DELETE_GLOBAL_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }, { variables }) => {
      if (data && variables)
        updateTemplateInCache({ cache, template: data.deleteGlobalTemplate, type: variables.type });
    }
  });

  const deleteTemplate = useCallback(
    (
      variables: ITemplateBaseDetails
    ): Promise<
      FetchResult<
        | {
            deleteTemplate?: ITemplateBaseDetails;
            deleteGlobalTemplate?: ITemplateBaseDetails;
          }
        | null
        | undefined
      >
    > => {
      if (!variables.company) return globalMutation({ variables });
      else return mutation({ variables });
    },
    [globalMutation, mutation]
  );

  return { deleteTemplate, loading: rest.loading || globalRest.loading };
};

export const useRestoreTemplate = () => {
  const [mutation, rest] = useMutation<{ restoreTemplate: ITemplateBaseDetails }, ITemplateBaseDetails>(
    RESTORE_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }, { variables }) => {
        if (data && variables) updateTemplateInCache({ cache, template: data.restoreTemplate, type: variables.type });
      }
    }
  );

  const restoreTemplate = useCallback((variables: ITemplateBaseDetails) => mutation({ variables }), [mutation]);

  return { restoreTemplate, ...rest };
};

export const useUpdateTemplate = (type: TEMPLATE_TYPE) => {
  const [mutation, rest] = useMutation<{ updateTemplate: ITemplateBaseDetails }>(UPDATE_TEMPLATE, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data) updateTemplateInCache({ cache, template: data.updateTemplate, type });
    }
  });

  const [globalMutation, globalRest] = useMutation<{ updateGlobalTemplate: ITemplateBaseDetails }>(
    UPDATE_GLOBAL_TEMPLATE,
    {
      context: { serializationKey: 'MUTATION', tracked: true },
      update: (cache, { data }) => {
        if (data) updateTemplateInCache({ cache, template: data.updateGlobalTemplate, type });
      }
    }
  );

  const updateTemplate = useCallback(
    (
      variables: IUpdateTemplateProps,
      currTemplate?: ITemplate
    ): Promise<
      FetchResult<
        | {
            updateTemplate?: ITemplateBaseDetails;
            updateGlobalTemplate?: ITemplateBaseDetails;
          }
        | null
        | undefined
      >
    > => {
      // Use global mutation if mutation should be global, or was previously global and is being set as private
      if (variables.isGlobal || (variables.isGlobal === false && currTemplate && !currTemplate.company))
        return globalMutation({ variables: { ...variables, type } });
      else return mutation({ variables: { ...variables, type } });
    },
    [globalMutation, mutation, type]
  );

  return { updateTemplate, loading: rest.loading || globalRest.loading };
};
