import { Field, FieldConfig } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { useFormFieldMeta } from '../form-field-meta';
import { FormTagsInput, IFormTagsInputProps } from './form-tags-input.component';
import { FormMultiSelectInput } from './form-multiselect-input.component';
import { FormSelectInput, IFormSelectInputProps } from './form-select-input.component';
import { IMultiSelectInputProps } from '../../input/multiselect-input.component';
import { InputType } from '../../input/input.type';
import { getInputClasses } from '../../input/input-utils';

export interface IFormInputProps extends FieldConfig {
  containerClass?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  type?: InputType;
  setFieldValue?: (field: string, value: unknown) => void;

  // Input specific props
  multiSelectProps?: IMultiSelectInputProps;
  selectProps?: IFormSelectInputProps;
  tagsProps?: IFormTagsInputProps;
}

export const FormInput = ({
  containerClass,
  multiSelectProps,
  selectProps,
  tagsProps,
  type = 'text',
  setFieldValue,
  ...props
}: IFormInputProps) => {
  const { hasError, error, touched } = useFormFieldMeta(props.name); // Assuming useFormFieldMeta provides touched status

  const errorMessage = hasError && touched ? <span className="my-1.5 text-red-500 text-sm">{error}</span> : undefined;
  const isCheckbox = type === 'checkbox';

  const { finalContainerClass, inputClasses } = getInputClasses({
    containerClass,
    hasError: hasError && touched,
    type
  });

  if (type === 'tags')
    return (
      <FormTagsInput
        setFieldValue={setFieldValue}
        {...props}
        {...tagsProps}
        inputClasses={inputClasses}
        error={errorMessage}
        containerClass={finalContainerClass}
      />
    );
  else if (type === 'multiselect')
    return (
      <div className="w-full">
        <FormMultiSelectInput
          setFieldValue={setFieldValue}
          {...props}
          {...multiSelectProps}
          inputClasses={inputClasses}
          error={errorMessage}
          containerClass={finalContainerClass}
        />
      </div>
    );
  else if (type === 'select')
    return (
      <div className="w-full">
        <FormSelectInput
          setFieldValue={setFieldValue}
          {...props}
          {...selectProps}
          inputClasses={inputClasses}
          error={errorMessage}
          containerClass={finalContainerClass}
        />
      </div>
    );

  return (
    <div className={finalContainerClass}>
      {type === 'phone' ? (
        <PhoneInput
          inputStyle={{
            width: '100%',
            border: '1px solid rgb(107, 114, 128)',
            height: '36px',
            borderWidth: '0px',
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px inset, rgb(209, 213, 219) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
            boxSizing: 'border-box'
          }}
          country={'ca'}
          value={props.value}
          onChange={(value) => setFieldValue && setFieldValue(props.name, value)}
        />
      ) : (
        <Field
          fid={props.id}
          type={type}
          as={type === 'textarea' ? 'textarea' : undefined}
          checked={isCheckbox ? !!props.value : undefined}
          {...props}
          className={inputClasses}
        />
      )}
      {errorMessage}
    </div>
  );
};
