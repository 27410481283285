import { IClient } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import {
  ITableFilterConfig,
  ITableModeConfig,
  ITableSearchConfig,
  TableWithActions
} from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IContactsTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  bordered?: boolean;
  contacts: ITableRowValues[];
  showEntity?: boolean;
  filterConfig: ITableFilterConfig<IClient>;
  modeConfig?: ITableModeConfig;
  searchConfig?: ITableSearchConfig;
}

export const ContactsTable: React.FC<IContactsTableProps> = ({
  bordered,
  children,
  isPersonalCompany,
  contacts,
  showEntity,
  ...rest
}) => {
  const headers = ['Name', 'Email', 'Status'];
  if (!isPersonalCompany) headers.push('Departments', 'Locations');
  if (showEntity) headers.push('Company');
  headers.push('');

  return (
    <>
      <TableWithActions bordered={bordered} loading={!contacts} headers={headers} values={contacts} {...rest}>
        {children}
      </TableWithActions>
    </>
  );
};
