import { useState } from 'react';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import { RegisterCompanyContainer } from './register-company-form.container';
import { SendInvitesNestedContainer } from '../../invite/send/send-invites-form-nested.container';
import { IFormFinishProps, IFormWithStepperProps } from '../../../_core/form/form.types';
import { Button } from '../../../_core/button/button.component';
import { ROUTE_PATHS } from '../../../../_routes';

interface IRegisterCompanySteps extends IFormWithStepperProps, IFormFinishProps {
  contentRef: React.RefObject<HTMLDivElement>;
  initialStep?: number;
  onError: (_?: string | null) => void;
  onFinish: (to?: string) => void;
  activeStep: number;
  setActiveStep: (step: number) => void;
}

export const RegisterCompanySteps = ({ activeStep, setActiveStep, onError, onFinish }: IRegisterCompanySteps) => {
  const [loading, setLoading] = useState(false);
  /*
   * Can go back from create company step to the initial stepper select create/join option.
   * But steps 1-4 are all actions on an already created firm.
   * So once we are there then we should not be able to backtrack beyond that range.
   */
  return (
    <>
      {activeStep === 0 && (
        <div className="sm:max-w-xl mx-auto">
          <h2 className="text-center text-2xl mb-4">Join an Organization</h2>
          <p className="font-light text-sm mb-4">
            If you want to join a pre-existing organization then reach out to your local account administrator and ask
            them to send you an invite.
          </p>
          <p className="font-light text-sm mb-4">
            Clicking on the link found in the invite email will get you started on the process to join that
            organization.
          </p>
          <div className="flex items-center my-8 w-full">
            <div className="w-full border-b border-dashed" />
            <span className="text-gray-400 mx-2 text-xs">OR</span>
            <div className="w-full border-b border-dashed" />
          </div>
          <h2 className="text-center text-2xl mb-4">Create an Organization</h2>
          <p className="font-light text-sm mb-4">
            Continue to create your own organization and get started using Request IQ!
          </p>
          <div className="flex-row justify-between mt-4">
            <Button onClick={() => setActiveStep(1)}>Create a new Organization</Button>
          </div>
        </div>
      )}
      {activeStep === 1 && (
        <RegisterCompanyContainer onBack={() => setActiveStep(0)} onSuccess={() => setActiveStep(2)} />
      )}
      {activeStep === 2 && (
        <SendInvitesNestedContainer
          onBack={() => setActiveStep(1)}
          onSkip={() => setActiveStep(3)}
          onSuccess={() => setActiveStep(3)}
          onError={onError}
        />
      )}
      {activeStep === 3 && (
        <div>
          <h1 className="text-center text-2xl mb-4">You're all set up!</h1>
          <p className="font-light text-sm mt-12 mb-4">
            The easiest way to get started is by drafting your first request for your clients.
          </p>
          <p className="font-light text-sm mt-4 mb-12">Jump in now and explore the platform at your own pace.</p>
          <div>
            <div className="flex-row justify-between mt-4">
              <Button
                disabled={loading}
                text="Explore"
                onClick={() => {
                  setLoading(true);
                  onFinish(ROUTE_PATHS.DASHBOARD);
                }}
              />
              <div className="flex justify-between mt-4">
                <div className="w-24">
                  <Button
                    icon={<ArrowLongLeftIcon className="w-6 h-6 mr-2" />}
                    onClick={() => setActiveStep(2)}
                    text="Back"
                    type="button"
                    variant="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
