import { ITemplateBaseDetails } from '../../../../../lib/types';
import { Button } from '../../../../_core/button/button.component';
import { ITableMultiselectActionProps } from '../../../../_core/table/table.types';
import { useDeleteTemplateAction } from './template-table-delete-action';
import { useRestoreTemplateAction } from './template-table-restore-action';

interface ITemplateTableMultiselectActionButtonProps extends ITableMultiselectActionProps<ITemplateBaseDetails> {
  selected: boolean[];
}

export const TemplateTableMultiselectActionButton: React.FC<ITemplateTableMultiselectActionButtonProps> = ({
  selected,
  ...actionProps
}) => {
  const deleteTemplateAction = useDeleteTemplateAction(actionProps);
  const restoreTemplateAction = useRestoreTemplateAction(actionProps);

  return (
    <>
      <Button
        className="mt-1"
        disabled={!selected.some((s) => s)}
        dropdown={{ options: [restoreTemplateAction, deleteTemplateAction] }}
        text="ACTION"
        size="large"
      />
    </>
  );
};
