import { TableCell } from './table-cell.component';
import { TableCheckbox } from './table-checkbox.component';
import { ITableRowProps } from './table.types';

export const TableRow = ({ onClick, onSelect, rowClasses, selected, selectable, values }: ITableRowProps) => {
  return (
    <tr
      className={`even:bg-gray-100 odd:bg-white border-b hover:bg-slate-100 ${onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick}
    >
      {selectable && <TableCheckbox checked={selected} onChange={onSelect} />}
      {values.map(({ children, key }, i) => (
        <TableCell
          key={key ?? 'table-row-child-' + i}
          rowClass={rowClasses?.length && rowClasses.length > i ? rowClasses[i] : undefined}
        >
          {children}
        </TableCell>
      ))}
    </tr>
  );
};
