import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import { useTransferOwnership } from '../company.service';
import { ITransferOwnershipParams, TransferOwnershipForm } from './transfer-ownership-form.component';
import { IMemberOverview } from '../../../../lib/types';
import { ICloseableDialogProps } from '../../../_core/core.types';

export interface ITransferOwnershipContainerProps extends ICloseableDialogProps {
  currMember?: IMemberOverview;
  members?: IMemberOverview[];
}

export const TransferOwnershipNestedContainer = ({
  close,
  currMember,
  members,
  onError,
  onSuccess,
  ...rest
}: IFormWithStepperProps & ITransferOwnershipContainerProps) => {
  const { transferOwnership } = useTransferOwnership();

  const handleSubmit = async ({ member }: ITransferOwnershipParams) => {
    await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

    try {
      await transferOwnership(member[0].value);
      onSuccess?.('Successfully updated ownership');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to transfer ownership';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }
  };

  if (!currMember || !members?.length) return <Loader />;

  return <TransferOwnershipForm members={members} onSubmit={handleSubmit} {...rest} />;
};
