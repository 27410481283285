const groupClasses = {
  first: 'border-y border-l rounded-s-md',
  middle: 'border-y border-l',
  last: 'border rounded-e-md'
};

const activeClasses = {
  active: 'bg-secondary text-white',
  inactive: 'bg-white text-secondary'
};

export const TableModeSelect = ({
  mode,
  onChange,
  options
}: {
  mode: string;
  onChange: (_: string) => void;
  options: string[];
}) => {
  return (
    <div className="-mt-5 mr-2">
      <div>
        <label htmlFor={'request-table-modes'} className="text-xs font-semibold h-5">
          Table Mode
        </label>
      </div>
      <div className="inline-flex rounded-md shadow-sm" role="group" id="request-table-modes">
        {options.map((o, i) => (
          <button
            key={'table-mode-select-' + o}
            type="button"
            className={`px-8 py-1.75 text-sm font-medium border-secondary_light ${
              i ? (i < options.length - 1 ? groupClasses.middle : groupClasses.last) : groupClasses.first
            } ${mode === o ? activeClasses.active : activeClasses.inactive}`}
            onClick={() => onChange(o)}
          >
            {o}
          </button>
        ))}
      </div>
    </div>
  );
};
