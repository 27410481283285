import { ITemplateBaseDetails, TEMPLATE_TYPE } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { TemplateTable, ITemplateTableRowValues } from './template-table.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { TemplateActionButton } from './template-action-button';
import { TemplateTableMultiselectActionButton } from './template-table-multiselect-action-button/template-table-multiselect-action-button';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig, useTableSelect } from '../../../_core/table/table-utils';
import { ITableFilterOption } from '../../../_core/table/table-with-actions.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { PreviewTemplateContainer } from '../../../domains/template/preview/preview-template.container';
import { GlobalIcon } from '../../../_core/icon/global-icon.component';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { EditTemplateContainer } from '../../../domains/template/edit/edit-template-form.container';
import { BlockTitles, BlockType } from '../../../_editor/_core/editor.const';

enum TemplateTableType {
  REQUEST = 'Request',
  FORM = 'Question'
}

interface ITemplateTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps {
  templates?: ITemplateBaseDetails[];
}

export const TemplateTableNestedContainer: React.FC<ITemplateTableNestedContainerProps> = ({
  templates,
  isAdmin,
  isPersonalCompany
}) => {
  const { isGlobalAdmin } = useFetchCurrUser();

  const [editTemplate, setEditTemplate] = useState<ITemplateBaseDetails | null>(null);
  const [previewTemplate, setPreviewTemplate] = useState<ITemplateBaseDetails | null>(null);
  const [requestTemplate, setRequestTemplate] = useState<ITemplateBaseDetails | null>(null);

  const [mode, setMode] = useState<string>(TemplateTableType.REQUEST);

  const showBlockType = useMemo(() => mode === TemplateTableType.FORM, [mode]);
  const filters = useMemo(() => {
    const newFilters: ITableFilterOption<ITemplateBaseDetails>[] = [];

    if (showBlockType)
      newFilters.push({
        containerClass: 'w-96',
        title: TableFilterKeys.TYPE,
        placeholder: 'Filter by Type',
        options: Object.values(BlockType),
        labeler: (value) => (value in BlockTitles ? BlockTitles[value as BlockType] : undefined),
        value: [],
        matcher: (curr: ITemplateBaseDetails, filterValue: string[]) => {
          return !filterValue.length || (!!curr.blockType && filterValue.includes(curr.blockType));
        }
      });

    return newFilters;
  }, [showBlockType]);

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true, filters, ownershipFilter: true });
  const [searchConfig, searchMatches] = useTableSearchConfig({ items: templates, keys: ['description', 'title'] });

  const templateRows = useMemo(() => {
    return searchMatches
      ?.filter((t) =>
        mode === TemplateTableType.REQUEST ? t.type === TEMPLATE_TYPE.REQUEST : t.type !== TEMPLATE_TYPE.REQUEST
      )
      .filter(filterFunc)
      ?.map((template, tIndex) => {
        const row: ITemplateTableRowValues = {
          template,
          values: [
            {
              children: (
                <div className="flex">
                  {!template.company && <GlobalIcon text="Global Template" />}
                  <span>{template.title}</span>
                </div>
              )
            },
            { children: activeStatus(!template.deletedAt) },
            { children: template.type },
            { children: template.description }
          ]
        };

        if (showBlockType)
          row.values.push({ children: template.blockType ? BlockTitles[template.blockType as BlockType] : null });

        row.values.push({
          children: (
            <TemplateActionButton
              index={tIndex}
              template={template}
              isGlobalAdmin={isGlobalAdmin}
              isPersonalCompany={isPersonalCompany}
              onCreateRequest={mode === TemplateTableType.REQUEST ? () => setRequestTemplate(template) : undefined}
              onEdit={() => setEditTemplate(template)}
              onPreview={() => setPreviewTemplate(template)}
            />
          )
        });

        return row;
      });
  }, [searchMatches, filterFunc, showBlockType, isGlobalAdmin, isPersonalCompany, mode]);

  const { clearSelected, getSelectedModels, onSelect, onSelectAll, selected } = useTableSelect({
    models: templates,
    rows: templateRows
  });

  if (!selected || !templateRows) return null;

  return (
    <>
      <TemplateTable
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        selected={selected}
        templates={templateRows}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        filterConfig={filterConfig}
        modeConfig={{
          onChange: setMode,
          options: Object.values(TemplateTableType),
          value: mode
        }}
        searchConfig={searchConfig}
        showBlockType={showBlockType}
      >
        <TemplateTableMultiselectActionButton
          getSelectedModels={getSelectedModels}
          selected={selected}
          clearSelected={clearSelected}
        />
      </TemplateTable>
      {!!requestTemplate && (
        <RIDialog open={!!requestTemplate} setOpen={(o) => setRequestTemplate((v) => (o ? v : null))}>
          <EditRequestContainer template={requestTemplate} />
        </RIDialog>
      )}
      {!!previewTemplate && (
        <RIDialog open={!!previewTemplate} setOpen={(o) => setPreviewTemplate((v) => (o ? v : null))}>
          <PreviewTemplateContainer templateId={previewTemplate._id} />
        </RIDialog>
      )}
      {!!editTemplate && (
        <RIDialog open={!!editTemplate} setOpen={(o) => setEditTemplate((v) => (o ? v : null))}>
          <EditTemplateContainer close={() => setEditTemplate(null)} template={editTemplate} />
        </RIDialog>
      )}
    </>
  );
};
