import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { inputClassesLineHeightStr } from './editor.const';
import { IRequiredBlockData, RequiredBlockTool } from './required-block';
import { ICreateTemplateBlockConfig } from './create-template-block';
import _ from 'lodash';
import { AutoSizeTextArea } from './autosizetextarea.component';
import { createRoot } from 'react-dom/client';

export interface RenderWithLabelParams {
  maxWidth?: string | null;
  editOnly?: { prefix?: string };
  readOnly: { className?: string; elementType: string };
}

export interface ILabelBlockData extends IRequiredBlockData {
  label?: string;
}

export class LabelBlockTool<T extends ILabelBlockData, U extends ICreateTemplateBlockConfig> extends RequiredBlockTool<
  T,
  U
> {
  constructor(props: BlockToolConstructorOptions<T>) {
    super({ ...props, data: { ...props.data, label: _.unescape(props.data.label ?? '') } });
  }

  protected renderWithLabel(params: RenderWithLabelParams = { readOnly: { elementType: 'span' } }): HTMLDivElement {
    super.render();
    if (!this.wrapper) this.wrapper = document.createElement('div');

    if (this.api.readOnly.isEnabled) {
      const questionLabel = document.createElement(params.readOnly.elementType);
      questionLabel.classList.add('label', ...inputClassesLineHeightStr.split(' '), 'w-full');

      if (params.maxWidth !== null) {
        if (params.maxWidth) questionLabel.classList.add(params.maxWidth);
        else questionLabel.classList.add('sm:max-w-60', 'md:max-w-96');
      }

      if (params.readOnly.className) {
        questionLabel.classList.remove('sm:text-xs');
        questionLabel.classList.add(params.readOnly.className, 'sm:!leading-6', '!py-1');
      }

      questionLabel.textContent = this.data.label ?? '';
      this.wrapper.prepend(questionLabel);
    } else {
      const labelContainer = document.createElement('div');
      labelContainer.classList.add('flex', 'label-container', 'w-full');
      if (params.maxWidth !== null) labelContainer.classList.add(params.maxWidth ?? 'max-w-[50%]');

      if (params.editOnly?.prefix) {
        const labelPrefix = document.createElement('span');
        labelPrefix.classList.add(
          'inline-flex',
          'items-center',
          'justify-center',
          'px-3',
          'text-gray-900',
          'bg-gray-200',
          'border',
          'rounded-s-md',
          'border-gray-300',
          'h-8',
          ...inputClassesLineHeightStr.split(' ')
        );
        labelPrefix.textContent = params.editOnly.prefix;
        labelContainer.prepend(labelPrefix);
      }

      // Render label input
      const labelInputContainer = document.createElement('div');
      labelInputContainer.classList.add('w-full');
      const root = createRoot(labelInputContainer);
      root.render(
        <AutoSizeTextArea
          hasPrefix={!!params.editOnly?.prefix}
          initialValue={this.data.label ?? ''}
          onChange={(e) => {
            this.data.label = (e as HTMLTextAreaElement).value;
          }}
        />
      );
      labelContainer.append(labelInputContainer);

      this.wrapper.prepend(labelContainer);
    }

    return this.wrapper;
  }

  async save(blockContent: HTMLElement): Promise<T> {
    const label = blockContent.querySelector('.label') as HTMLInputElement | undefined;

    const data = await super.save(blockContent);
    return { ...data, label: (label?.value ?? data.label)?.trim() };
  }

  validate({ label }: ILabelBlockData) {
    return !!label;
  }
}
