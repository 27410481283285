import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { IUpdateCompanyFormValues, UpdateCompanyForm } from './update-company-form.component';
import { ICompany } from '../../../../lib/types';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { ICloseableDialogProps } from '../../../_core/core.types';
import { useUpdateCompany } from '../company.service';

interface IUpdateCompanyContainerProps extends ICloseableDialogProps {
  company: ICompany;
}

export const UpdateCompanyContainer = ({ company, close }: IUpdateCompanyContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { updateCompany } = useUpdateCompany();

  const handleUpdate = async ({ name }: IUpdateCompanyFormValues) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateCompany({ name });
      setSubmitError(null);
      setSuccess('Successfully updated company!');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to update company.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Company"
    >
      <UpdateCompanyForm name={company.name} onSubmit={handleUpdate} />
    </FormContainer>
  );
};
