import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../../_core/button/button-dropdown-option-component';
import { useDeleteClient, useRestoreClient } from '../../clients/client.service';
import { IDepartment, ILocation, IClient } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { NullableContact } from './contacts-table.types';
import { useDeleteEntityMember, useRestoreEntityMember } from '../../entity-members/entity-members.service';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';

export interface IContactsActionProps {
  client?: IClient;
}

export interface IContactsActionButtonProps extends IContactsActionProps, IPersonalCompanyProps {
  departments?: IDepartment[];
  editingDisabled?: boolean;
  entityId: string;
  locations?: ILocation[];
  setShowEditDepartmentsModal: React.Dispatch<React.SetStateAction<NullableContact>>;
  setShowEditLocationsModal: React.Dispatch<React.SetStateAction<NullableContact>>;
}

export const ContactsActionButton: React.FC<IContactsActionButtonProps> = ({
  departments,
  editingDisabled,
  entityId,
  isPersonalCompany,
  locations,
  client,
  setShowEditDepartmentsModal,
  setShowEditLocationsModal
}) => {
  const navigate = useNavigate();

  const { deleteClient, loading: removingClient } = useDeleteClient();
  const { deleteEntityMember, loading: deletingEntityMember } = useDeleteEntityMember();
  const { restoreClient, loading: restoringClient } = useRestoreClient();
  const { restoreEntityMember, loading: restoringEntityMember } = useRestoreEntityMember({ entityId });

  const editContactsDepartmentsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(client ?? null),
      text: 'Edit Departments'
    }),
    [departments?.length, client, setShowEditDepartmentsModal]
  );

  const editContactsLocationsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(client ?? null),
      text: 'Edit Locations'
    }),
    [locations?.length, client, setShowEditLocationsModal]
  );

  // const onResendInvite = useCallback(async () => {
  //   if (invite)
  //     try {
  //       const result = await resendClientInvite(invite);
  //       if (!result.data?.resendClientInvite.sentAt) toast.error('Unable to deliver invite');
  //       else toast.success(`Resent invite`);
  //     } catch (err) {
  //       showError('Unable to resend invite', err as Error);
  //     }
  // }, [invite, resendClientInvite]);

  // const resendInviteAction: IButtonDropdownOptionProps = useMemo(
  //   () => ({ onClick: onResendInvite, text: 'Resend Invite' }),
  //   [onResendInvite]
  // );

  const onRestoreClient = useCallback(async () => {
    if (client?.entityMemberId && client.entityDeletedAt) {
      try {
        await restoreEntityMember({ _id: client.entityMemberId });
        toast.success(`Restored company member`);
      } catch (err) {
        showError('Unable to restore company member', err as Error);
      }
    } else if (client?.deletedAt) {
      try {
        await restoreClient(client);
        toast.success(`Restored contact`);
      } catch (err) {
        showError('Unable to restore contact', err as Error);
      }
    }
  }, [client, restoreClient, restoreEntityMember]);

  const restoreClientAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreClient, text: 'Restore' }),
    [onRestoreClient]
  );

  const onDeleteContacts = useCallback(async () => {
    if (client?.entityMemberId) {
      try {
        await deleteEntityMember({ _id: client.entityMemberId });
        toast.success(`Removed contact`);
      } catch (err) {
        showError('Unable to remove contact', err as Error);
      }
    } else if (client) {
      try {
        await deleteClient(client);
        toast.success(`Deleted contact`);
      } catch (err) {
        showError('Unable to delete contact', err as Error);
      }
    }
  }, [client, deleteEntityMember, deleteClient]);

  const deleteContactsAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteContacts, text: client?.entityMemberId ? 'Remove' : 'Delete' }),
    [client?.entityMemberId, onDeleteContacts]
  );

  const viewAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: () => navigate(ROUTE_PATHS.CLIENT_PROFILE + '/' + client?._id), text: 'View Contact' }),
    [client?._id, navigate]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] =
      !isPersonalCompany && !editingDisabled
        ? [viewAction, editContactsDepartmentsAction, editContactsLocationsAction]
        : [viewAction];

    if ((client?.entityMemberId && client?.entityDeletedAt) || (!client?.entityMemberId && client?.deletedAt))
      newOptions.push(restoreClientAction);
    else if (
      !client ||
      (client.entityMemberId && !client.entityDeletedAt) ||
      (!client.entityMemberId && !client.deletedAt)
    )
      newOptions.push(deleteContactsAction);

    return newOptions;
  }, [
    isPersonalCompany,
    editingDisabled,
    viewAction,
    editContactsDepartmentsAction,
    editContactsLocationsAction,
    client,
    restoreClientAction,
    deleteContactsAction
  ]);

  return (
    <Button
      dropdown={{ options }}
      text="ACTION"
      size="xl"
      loading={removingClient || restoringClient || deletingEntityMember || restoringEntityMember}
    />
  );
};
