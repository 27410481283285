import { Navigate, useParams } from 'react-router-dom';
import { AcceptInviteContainer } from '../../domains/invite/accept/accept-invite-form.container';
import { ROUTE_PATHS } from '../../../_routes';
import { useQueryParams } from '../../../services/routing.service';
import { InviteType } from '../../domains/invite/get-by-code/get-invite-by-code-form.types';

export const ViewInvitePageContainer = () => {
  const params = useParams();
  const queryParams = useQueryParams();
  if (!params.code) return <Navigate to={ROUTE_PATHS.JOIN} />;
  return (
    <AcceptInviteContainer
      code={params.code}
      type={(queryParams.get('type') as InviteType | null) || InviteType.STAFF}
    />
  );
};
