import { IRequest, IRequestOverview } from '../../../../lib/types';
import React, { PropsWithChildren, useMemo } from 'react';
import {
  ITableFilterConfig,
  ITableModeConfig,
  ITableSearchConfig,
  TableWithActions
} from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { useWindowSize } from 'usehooks-ts';
import { ITableRowClassConfig } from '../../../_core/table/table.types';

interface IRequestTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  filterConfig: ITableFilterConfig<IRequest>;
  modeConfig?: ITableModeConfig;
  requests: IRequestTableRowValues[];
  searchConfig: ITableSearchConfig;
  editingDisabled?: boolean;
  draftsMode?: boolean;
}

export interface IRequestTableRowValues extends ITableRowValues {
  request: IRequestOverview;
}

const baseDraftColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-20 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-20' },
  { cell: 'w-24' },
  { cell: 'w-20' },
  { cell: 'w-32' }
];

const baseInboxColSizes: (ITableRowClassConfig | null)[] = [
  { cell: 'w-20 text-center' },
  { cell: 'w-20 text-center' },
  { cell: 'w-32' },
  { cell: 'w-32' },
  null, // Allow title col to grow
  { cell: 'w-26' },
  { cell: 'w-20 text-center' },
  { cell: 'w-26 text-center' },
  { cell: 'w-24' },
  { cell: 'w-20' },
  { cell: 'w-32' }
];

export const RequestTable: React.FC<IRequestTableProps> = ({ children, draftsMode, requests, ...rest }) => {
  const headers = ['Last Update'];
  if (!draftsMode) headers.push('Sent At');
  headers.push('Contact', 'Company', 'Title', 'Type', 'Deadline');
  if (!draftsMode) headers.push('Completion');
  headers.push('Owner', 'Status', '');

  const { width } = useWindowSize();
  const isExtraSmallWindow = useMemo(() => width < 1280, [width]);
  const isSmallWindow = useMemo(() => width < 1368, [width]);
  const isLargeWindow = useMemo(() => width > 1500, [width]);

  const rowClasses = useMemo(() => {
    if (isExtraSmallWindow) return [{ cell: 'w-20 text-center' }];
    if (isSmallWindow) return draftsMode ? baseDraftColSizes : baseInboxColSizes;

    // Handle large window sizes
    const sizes = [...(draftsMode ? baseDraftColSizes : baseInboxColSizes)];

    // Update contact and company cols
    const contactIndex = draftsMode ? 1 : 2;
    sizes[contactIndex] = isLargeWindow ? { cell: 'w-40' } : { cell: 'w-36' };
    sizes[contactIndex + 1] = isLargeWindow ? { cell: 'w-48' } : { cell: 'w-40' };

    // Update owner col
    sizes[sizes.length - 3] = isLargeWindow ? { cell: 'w-32' } : { cell: 'w-28' };

    // Update deadline col
    sizes[sizes.length - (draftsMode ? 4 : 5)] = isLargeWindow ? { cell: 'w-32' } : { cell: 'w-28' };

    return sizes;
  }, [draftsMode, isExtraSmallWindow, isLargeWindow, isSmallWindow]);

  return (
    <>
      <TableWithActions loading={!requests} headers={headers} rowClasses={rowClasses} values={requests} {...rest}>
        {children}
      </TableWithActions>
    </>
  );
};
