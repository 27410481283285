import { useCallback, useMemo, useState } from 'react';
import { Button } from '../../../../_core/button/button.component';
import { useCompanyDepartments } from '../../../../domains/departments/department.service';
import { useDeleteStaffAction } from './staff-table-delete-action';
import { IStaffTableMultiselectActionProps } from './staff-table-multiselect-action-button.types';
import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { RIDialog } from '../../../../_core/dialog/dialog.component';
import { SelectDepartmentsContainer } from '../../../../domains/departments/select/select-departments-form.container';
import { useCompanyLocations } from '../../../../domains/locations/location.service';
import { SelectLocationsContainer } from '../../../../domains/locations/select/select-locations-form.container';
import { SelectRolesContainer } from '../../../../domains/staff/select-roles/select-roles-form.container';
import { useResendInviteAction } from './staff-table-resend-invite-action';
import { IInvite, IMemberOverview } from '../../../../../lib/types';
import { IStaffTableRowValues } from '../staff-table.component';
import { SelectedStaffModels } from '../staff-table.types';
import { IAdminProps } from '../../../../_core/core.types';

interface IStaffTableMultiselectActionButtonProps
  extends Omit<IStaffTableMultiselectActionProps, 'getSelectedStaffModels'>,
    IAdminProps {
  selected: boolean[];
  staff: IStaffTableRowValues[];
}

export const StaffTableMultiselectActionButton: React.FC<IStaffTableMultiselectActionButtonProps> = ({
  isAdmin,
  selected,
  staff,
  ...actionProps
}) => {
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState(false);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState(false);
  const [showEditRolesModal, setShowEditRolesModal] = useState(false);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const getSelectedStaffModels = useCallback(() => {
    if (selected) {
      const selectedInvites: IInvite[] = [];
      const selectedMembers: IMemberOverview[] = [];
      selected.forEach((s, i) => {
        if (s && i < staff.length) {
          const curr = staff[i];
          if (curr.member) selectedMembers.push(curr.member);
          else if (curr.invite) selectedInvites.push(curr.invite);
        }
      });

      return { selectedInvites, selectedMembers } as SelectedStaffModels;
    }
  }, [selected, staff]);

  const finalActionProps = { ...actionProps, getSelectedStaffModels };
  const deleteStaffAction = useDeleteStaffAction(finalActionProps);
  const resendInviteAction = useResendInviteAction(finalActionProps);

  const dropdownOptions = useMemo(() => {
    const editStaffDepartmentsAction: IButtonDropdownOptionProps = {
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(true),
      text: 'Edit Departments'
    };

    const editStaffLocationsAction: IButtonDropdownOptionProps = {
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(true),
      text: 'Edit Locations'
    };

    const editStaffRolesAction: IButtonDropdownOptionProps = {
      onClick: () => setShowEditRolesModal(true),
      text: 'Edit Roles'
    };

    const options: IButtonDropdownOptionProps[] = [editStaffDepartmentsAction, editStaffLocationsAction];

    if (isAdmin) options.push(editStaffRolesAction);
    options.push(resendInviteAction);
    if (isAdmin) options.push(deleteStaffAction);

    return options;
  }, [deleteStaffAction, departments?.length, isAdmin, locations?.length, resendInviteAction]);

  return (
    <>
      <Button disabled={!selected.some((s) => s)} dropdown={{ options: dropdownOptions }} text="ACTION" size="large" />
      {!!departments && (
        <RIDialog open={showEditDepartmentsModal} setOpen={setShowEditDepartmentsModal}>
          <SelectDepartmentsContainer departments={departments} {...finalActionProps} />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={showEditLocationsModal} setOpen={setShowEditLocationsModal}>
          <SelectLocationsContainer locations={locations} {...finalActionProps} />
        </RIDialog>
      )}
      <RIDialog open={showEditRolesModal} setOpen={setShowEditRolesModal}>
        <SelectRolesContainer {...finalActionProps} />
      </RIDialog>
    </>
  );
};
