import { Fragment, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Tag, TagSize } from '../tag.component';
import { UpDownIcon } from '../icon/icon.components';
import { useOnClickOutside } from '../utils/click';

export interface IInputProps {
  containerClass?: string;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  value: INullableMultiSelectOption[];
  setValue: (_: IMultiSelectOption[]) => void;

  // Form specific fields
  hasBeenTouched?: boolean;
  setHasBeenTouched?: (_: boolean) => void;
  setTouched?: (_: boolean) => void;
}

export interface IMultiSelectOption {
  disabled?: boolean;
  isNew?: boolean;
  group?: string;
  label?: string;
  secondaryLabel?: string;
  value: string;
}

export type INullableMultiSelectOption = IMultiSelectOption | null;

export interface IMultiSelectInputProps {
  canAdd?: boolean;
  groupOrder?: string[];
  inputClasses?: string;
  noMargin?: boolean;
  nullable?: boolean;
  onAdd?: (_: string) => void;
  options?: IMultiSelectOption[];
  singleSelect?: boolean;
  skipSort?: boolean;
  tagSize?: TagSize;
}

const SELECT_ALL = 'Select All';

export const MultiSelectInput = ({
  canAdd,
  children,
  containerClass = 'w-2/3',
  inputClasses,
  value,
  setValue,
  noMargin,
  nullable,
  options = [],
  placeholder = 'Select one or more options',
  singleSelect,
  groupOrder,
  tagSize,

  // Form specific fields
  hasBeenTouched,
  setHasBeenTouched,
  setTouched
}: IMultiSelectInputProps & IInputProps & PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  // On click outside of the component, close the dropdown
  const ref = useRef(null);
  const handleClickOutside = useCallback(() => {
    if (setHasBeenTouched && hasBeenTouched) {
      if (setTouched) setTouched(true);
      handleClose();
    } else if (!setHasBeenTouched) handleClose();
  }, [hasBeenTouched, setHasBeenTouched, setTouched]);

  useOnClickOutside(ref, handleClickOutside);

  const sortedOptions = useMemo(() => {
    const groupOrderLength = groupOrder?.length ?? 0;

    const sorted = [...options].sort((a, b) => {
      // Sort by group order first
      let aGroupIndex = groupOrder?.findIndex((g) => g === a.group);
      if (aGroupIndex === undefined || aGroupIndex === -1) aGroupIndex = groupOrderLength;

      let bGroupIndex = groupOrder?.findIndex((g) => g === b.group);
      if (bGroupIndex === undefined || bGroupIndex === -1) bGroupIndex = groupOrderLength;

      if (aGroupIndex !== bGroupIndex) return aGroupIndex - bGroupIndex;

      // Fallback to sorting by title
      const aTitle = a.label ?? a.value;
      const bTitle = b.label ?? b.value;
      return aTitle.localeCompare(bTitle);
    });

    // Add group opt titles to options list
    const insertOps: { group: string; insertIndex: number }[] = [];
    groupOrder?.forEach((group) => {
      const firstGroupMatchIndex = sorted.findIndex((o) => o.group === group);
      if (firstGroupMatchIndex >= 0) insertOps.push({ group, insertIndex: firstGroupMatchIndex });
    });

    insertOps.forEach(({ group, insertIndex }, offset) => {
      sorted.splice(insertIndex + offset, 0, { disabled: true, value: group });
    });

    return sorted;
  }, [groupOrder, options]);

  // Add select all option, and reduce shown options to only those that match the search string
  const optionsWithAll = useMemo(
    () => (sortedOptions.length && !singleSelect ? [{ value: 'Select All' }, ...sortedOptions] : sortedOptions),
    [sortedOptions, singleSelect]
  );
  const filteredOptions = useMemo(() => {
    const filtered = optionsWithAll.filter((option) => {
      const textToMatch = [option.label ?? option.value, option.secondaryLabel].filter((v) => v) as string[];
      return (!search || !option.disabled) && textToMatch.some((t) => t.toLowerCase().includes(search.toLowerCase()));
    });

    if (canAdd && search) return [{ isNew: true, label: `Add "${search}"`, value: search }, ...filtered];
    return filtered;
  }, [canAdd, search, optionsWithAll]);

  const handleSelect = useCallback(
    (initialSelected: INullableMultiSelectOption | INullableMultiSelectOption[]) => {
      if (singleSelect) {
        // Handle single select
        const selected = initialSelected as IMultiSelectOption;
        setValue(value && value.length && value[0] === selected ? [] : [selected]);
        handleClose();
      } else {
        // Handle multiple select
        const selected = initialSelected as IMultiSelectOption[];
        let selectedWithoutDuplicates: IMultiSelectOption[] = [];
        for (let i = 0; i < selected.length; i++) {
          const option = selected[i];
          if (!selectedWithoutDuplicates.find(({ value }) => value === option.value))
            selectedWithoutDuplicates.push(option);
          else {
            selectedWithoutDuplicates = selectedWithoutDuplicates.filter(({ value }) => value !== option.value);
          }
        }

        // Handle selecting all options if the select all option was selected
        if (selectedWithoutDuplicates.find(({ value }) => value === SELECT_ALL)) {
          const newSelected = selectedWithoutDuplicates.filter(
            ({ disabled, value }) => !disabled && value !== SELECT_ALL
          );

          sortedOptions.forEach((option) => {
            if (!option.disabled && !newSelected.find(({ value }) => value === option.value)) newSelected.push(option);
          });

          setValue(newSelected);
          handleClose();
        } else {
          setValue(selectedWithoutDuplicates);
          if (filteredOptions.length === 1) handleClose();
        }
      }
    },
    [singleSelect, setValue, value, sortedOptions, filteredOptions.length]
  );

  const handleRemove = useCallback(
    (tag: INullableMultiSelectOption) => {
      setValue((value.filter((t) => t && t !== tag) as IMultiSelectOption[]) ?? []);
    },
    [value, setValue]
  );

  // Show the dropdown if the input is opened manually or if there is a search string
  const showOptions = open || search.length > 0;

  const renderComboBoxContents = useCallback(() => {
    return (
      <div className={`relative ${noMargin ? '' : 'mt-1'}`}>
        <div className="flex w-full cursor-default overflow-hidden">
          {/* Single select input value is not shown until an interaction, even though the value is properly set */}
          <Combobox.Input
            className={`w-full border-none py-1.5 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${inputClasses}`}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder}
            type="text"
            value={
              search
                ? search
                : singleSelect && value.length && value[0]
                ? value[0].label ?? value[0].secondaryLabel ?? value[0].value
                : ''
            }
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            onClick={() => setOpen((o) => !o)}
          >
            <UpDownIcon open={showOptions} />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setSearch('')}
          show={showOptions}
        >
          <Combobox.Options
            static
            className={`shadow top-100 bg-white z-30 w-full lef-0 rounded max-h-72 overflow-y-auto absolute mt-2 border-2 border-indigo-400 list-none !pl-0 !mb-0`}
          >
            {!filteredOptions.length ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
            ) : (
              filteredOptions.map((option, i) => {
                const selected = !!value?.find((v) => v?.value === option.value);
                return (
                  <Combobox.Option
                    key={option.value + i}
                    disabled={option.disabled}
                    value={option}
                    // style={{ maxWidth: '95%' }}
                    className={({ active, disabled }) =>
                      `relative cursor-default select-none pl-10 pr-4 text-sm mx-auto !m-0 ${
                        i !== filteredOptions.length - 1 ? 'border-b border-slate-300' : ''
                      } ${active ? 'bg-teal-600 text-white' : selected ? 'bg-green-200' : 'text-gray-900'} ${
                        disabled ? 'bg-gray-400 text-white text-xs' : ''
                      }`
                    }
                  >
                    {({ active, disabled }) => {
                      const showSelected = !disabled;

                      // Only show primary label if it is explicitly set or if a secondary label doesn't exist then show the option value as primary label
                      const showPrimaryLabel = option.label || !option.secondaryLabel;
                      return (
                        <>
                          {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
                          <span
                            className={`block truncate ${selected && showSelected ? 'font-medium' : 'font-normal'}`}
                          >
                            {option.label ? option.label : showPrimaryLabel ? option.value : ''}
                            <span className={`text-xs opacity-60 ${showPrimaryLabel ? 'ml-2' : ''}`}>
                              {option.secondaryLabel}
                            </span>
                          </span>
                          {selected && showSelected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                );
              })
            )}
          </Combobox.Options>
        </Transition>
      </div>
    );
  }, [filteredOptions, inputClasses, noMargin, placeholder, search, showOptions, singleSelect, value]);

  return (
    <div className={containerClass} onClick={setHasBeenTouched ? () => setHasBeenTouched(true) : undefined}>
      <div className="relative">
        {singleSelect ? (
          // @ts-expect-error Because I said so
          <Combobox nullable={nullable} value={value} onChange={handleSelect} ref={ref}>
            {renderComboBoxContents()}
          </Combobox>
        ) : (
          <Combobox value={value} onChange={handleSelect} multiple ref={ref}>
            {renderComboBoxContents()}
          </Combobox>
        )}
        {children}
        {!singleSelect && (
          <div className="z-1">
            {value
              .filter((v) => v !== null)
              .map((tag, i) => (
                <Tag
                  key={'tag-' + tag + i}
                  onRemove={() => handleRemove(tag)}
                  tag={tag.isNew ? tag.value : tag.label ?? tag.secondaryLabel ?? tag.value}
                  size={tagSize}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
