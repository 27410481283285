import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';

export interface ICreateEntityFormValues {
  name: string;
}

export const CreateEntityForm = ({ onSubmit }: IRIFormProps<ICreateEntityFormValues>) => {
  const validateForm = (values: ICreateEntityFormValues) => {
    const errors: FormikErrors<ICreateEntityFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values) => onSubmit({ name: values.name.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<ICreateEntityFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel id="name" name="name" label="Company Name" value={props.values.name} />
          <FormButton loading={props.isSubmitting} text="Next" />
        </Form>
      )}
    </Formik>
  );
};
