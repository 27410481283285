import {
  BlockToolConstructorOptions,
  BlockToolData,
  ToolConfig,
  TunesMenuConfigDefaultItem
} from '@editorjs/editorjs/types/tools';
import { TEMPLATE_TYPE } from '../../../lib/types';
import { BlockAPI, OutputBlockData } from '@editorjs/editorjs';
import toast from 'react-hot-toast';
import { BaseBlockTool } from './base-block';
import { EditorSaveResult } from './types';

export interface ICreateBlockTemplateParams {
  data: OutputBlockData;
  type?: TEMPLATE_TYPE;
}

export interface ICreateTemplateBlockConfig {
  disabled: boolean;
  onCreateTemplate?: (_: ICreateBlockTemplateParams) => void;
  onSave: () => Promise<EditorSaveResult>;
  readOnly: boolean;
  requestId: string;
}

export class CreateTemplateBlockTool<
  T extends BlockToolData,
  U extends ICreateTemplateBlockConfig
> extends BaseBlockTool<T> {
  protected block?: BlockAPI;
  protected config: ToolConfig<U>;
  protected data: T;

  protected settings: TunesMenuConfigDefaultItem[] = [
    {
      name: 'createTemplate',
      label: 'Create Template',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6"><path strokeLinecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" /></svg>',
      onActivate: () => this.acceptTuneView()
    }
  ];

  constructor(props: BlockToolConstructorOptions) {
    super(props);
    const { block, config, data } = props;
    this.block = block;
    this.config = config;
    this.data = data;
  }

  // Block Settings
  private acceptTuneView() {
    this.settings.forEach((params) => {
      if (params.name === 'createTemplate') {
        const content = document.getElementById(this.wrapperId);
        if (content) {
          this.save(content).then((updatedData) => {
            if (updatedData && this.validate(updatedData))
              this.config.onCreateTemplate?.({
                data: { data: updatedData, type: this.block?.name ?? 'unknown', id: this.block?.id ?? '' }
              });
            else toast.error('Cannot create a template from a block in an invalid state');
          });
        } else toast.error('Cannot find block content to create template from');
      }
    });
  }

  renderSettings() {
    return this.settings;
  }
}
