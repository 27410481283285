import { ICompanyInviteQueryParams, ICompanyInviteResult } from './invite.types';
import { GET_COMPANY_INVITES } from './invite.queries';
import { IInviteOverview, INVITE_STATUS } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';

export const readInvitesCache = ({ cache }: IApolloCacheParams) =>
  cache.readQuery<ICompanyInviteResult, ICompanyInviteQueryParams>({
    query: GET_COMPANY_INVITES,
    variables: { status: INVITE_STATUS.PENDING }
  })?.invites;

export const writeInvitesCache = ({ cache, invites }: IApolloCacheParams & { invites: IInviteOverview[] }) => {
  cache.writeQuery({
    query: GET_COMPANY_INVITES,
    data: { invites },
    variables: { status: INVITE_STATUS.PENDING }
  });
};
