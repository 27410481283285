import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ROLE, ROLES_FOR_LABEL, ROLE_LABELS } from '../../../../lib/types';
import { ISelectedRolesProps } from '../../../../lib/query.types';
import { ROLE_OPTIONS } from '../../../../_configuration';
import { Loader } from '../../../_core/loader.component';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import { sortRoles } from '../../../../utils/role.helpers';
import { useMemo } from 'react';

interface IEditRolesFormFieldProps {
  roles: IMultiSelectOption[];
}

interface IEditRolesFormProps extends IRIFormProps<ISelectedRolesProps> {
  roles?: ROLE[];
}

export const EditRolesForm = ({ onSubmit, roles }: IEditRolesFormProps) => {
  const validateForm = (values: IEditRolesFormFieldProps) => {
    const errors: FormikErrors<IEditRolesFormFieldProps> = {};

    if (!values.roles.length) {
      errors.roles = 'Required';
    }

    return errors;
  };

  const initialRoles = useMemo(() => {
    if (roles?.length) return [[...roles].sort(sortRoles)[0]];
    return [];
  }, [roles]);

  if (!initialRoles) return <Loader />;

  return (
    <Formik
      initialValues={
        {
          roles: initialRoles.map((value) => ({ value, label: ROLE_LABELS[value] }))
        } as IEditRolesFormFieldProps
      }
      onSubmit={({ roles }) => onSubmit({ roles: roles.map(({ value }) => ROLES_FOR_LABEL[value]) })}
      validate={validateForm}
    >
      {(props: FormikProps<IEditRolesFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="roles"
            name="roles"
            label="Roles"
            value={props.values.roles}
            type="multiselect"
            placeholder="Select Roles"
            multiSelectProps={{ options: ROLE_OPTIONS, singleSelect: true, skipSort: true }}
          />
          <div className="flex row justify-around">
            <FormButton loading={props.isSubmitting} text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
