import { useMemo, useState } from 'react';
import { ITemplate, TEMPLATE_TYPE } from '../../../lib/types';
import { useLazyTemplate, useTemplates } from '../../domains/template/template.service';
import { EditorApolloProvider } from '../_core/editor-apollo-provider';
import { showError } from '../../../lib/utils';
import {
  IMultiSelectOption,
  INullableMultiSelectOption,
  MultiSelectInput
} from '../../_core/input/multiselect-input.component';
import { getInputClasses } from '../../_core/input/input-utils';
import { BlockTitles, BlockType } from '../_core/editor.const';

interface ISelectTemplateInput {
  onSelectTemplate: (_: ITemplate) => void;
  type: TEMPLATE_TYPE;
}

const SelectTemplateInput = ({ onSelectTemplate, type }: ISelectTemplateInput) => {
  const { getTemplate, loading: loadingTemplate } = useLazyTemplate();
  const { templates } = useTemplates([TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.SECTION]);

  const { finalContainerClass, inputClasses } = getInputClasses({ type: 'multiselect' });

  const [selected, setSelected] = useState<INullableMultiSelectOption[]>([]);

  const onSelect = (newSelected: INullableMultiSelectOption[]) => {
    const nonNullSelected = newSelected.filter((v) => v) as IMultiSelectOption[];

    setSelected(nonNullSelected);
    if (nonNullSelected?.length) {
      const variables = { _id: nonNullSelected[0].value };
      getTemplate({ variables })
        .then(({ data }) => {
          if (data?.template) {
            // Force question groups and section to appropriate block type when using in opposing editor areas
            const fittedTemplate = { ...data.template, blocks: [...data.template.blocks.map((b) => ({ ...b }))] }; // Make mutable copy of blocks
            if (type !== data.template.type && fittedTemplate.blocks.length === 1)
              if (type === TEMPLATE_TYPE.SECTION) fittedTemplate.blocks[0].type = BlockType.Section;
              else if (type === TEMPLATE_TYPE.BLOCK) fittedTemplate.blocks[0].type = BlockType.QuestionGroup;

            onSelectTemplate(fittedTemplate);
          } else setSelected([]);
        })
        .catch((err) => {
          showError('Failed to fetch template', err);
          setSelected([]);
        });
    }
  };

  const filteredTemplates = useMemo(
    () =>
      templates
        ?.filter((t) => {
          if (t.deletedAt) return false;
          if (type === t.type) return true;
          if (type === TEMPLATE_TYPE.SECTION && t.type === TEMPLATE_TYPE.BLOCK && t.blockType === 'questionGroup')
            return true;
          if (type === TEMPLATE_TYPE.BLOCK && t.type === TEMPLATE_TYPE.SECTION) return true;

          return false;
        })
        .map(
          ({ _id: value, title: label, description: secondaryLabel, blockType }) =>
            ({
              value,
              label,
              secondaryLabel,
              group: blockType ? BlockTitles[blockType as BlockType] : undefined
            } as IMultiSelectOption)
        ),
    [templates, type]
  );

  return (
    <>
      <label htmlFor="ids" className="block text-sm font-medium leading-6 text-gray-900">
        Select a Template to add to form:
      </label>
      <div className="w-full">
        <MultiSelectInput
          nullable
          singleSelect
          disabled={loadingTemplate}
          options={filteredTemplates}
          inputClasses={`${inputClasses} !pzy-1`}
          containerClass={finalContainerClass}
          placeholder="Select a template"
          setValue={onSelect}
          value={selected}
          groupOrder={Object.values(BlockTitles)}
        />
      </div>
    </>
  );
};

export const SelectTemplateInputContainer = (props: ISelectTemplateInput) => {
  return (
    <EditorApolloProvider>
      <SelectTemplateInput {...props} />
    </EditorApolloProvider>
  );
};
