import { useCallback, useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import {
  ISetSelectedFilterProps,
  ITableFilterConfig,
  ITableFilterOption,
  ITableSearchConfig
} from './table-with-actions.component';
import _ from 'lodash';
import { ACTIVE_STATUS, Deletable, Identifiable, OptionalCompany } from '../../../lib/types';
import { IdentifiableSelected, TableFilterKeys } from './table.types';
import { OrderedOwnershipLevels, Ownership } from '../../../lib/global.types';
import { usePrev } from '../../../lib/utils';
import { ITableRowValues } from './table.component';

interface FilterComp {
  activeStatusFilter?: boolean;
  filters?: { value: string[]; options: string[] }[];
  ownershipFilter?: boolean;
}

export const activeStatusMatcher = (curr: Deletable, filterValue: string[]) => {
  if (!filterValue.length) return true;
  if (curr.deletedAt) return filterValue.includes(ACTIVE_STATUS.INACTIVE);
  return filterValue.includes(ACTIVE_STATUS.ACTIVE);
};

export const ownershipMatcher = (curr: OptionalCompany, filterValue: string[]) => {
  if (!filterValue.length) return true;
  return (
    (filterValue.includes(Ownership.GLOBAL) && !curr.company) ||
    (filterValue.includes(Ownership.ORGANIZATION) && !!curr.company)
  );
};

export const useTableFilterConfig = <T>(props: {
  activeStatusFilter?: boolean;
  filters?: ITableFilterOption<T>[];
  ownershipFilter?: boolean;
}): [ITableFilterConfig<T>, (_: T) => boolean] => {
  const buildFilters = useCallback(() => {
    return [
      props.activeStatusFilter
        ? {
            title: TableFilterKeys.STATUS,
            placeholder: 'Filter by status',
            options: Object.values(ACTIVE_STATUS),
            value: [ACTIVE_STATUS.ACTIVE],
            matcher: activeStatusMatcher
          }
        : null,
      props.ownershipFilter
        ? {
            title: TableFilterKeys.OWNERSHIP,
            placeholder: 'Filter by ownership',
            options: OrderedOwnershipLevels,
            value: [],
            matcher: ownershipMatcher
          }
        : null,
      ...(props.filters ?? [])
    ].filter((v) => !!v) as ITableFilterOption<T>[];
  }, [props.activeStatusFilter, props.filters, props.ownershipFilter]);

  const [filters, setFilters] = useState<ITableFilterOption<T>[]>([]);

  const prevFilterProps = usePrev<FilterComp>({
    activeStatusFilter: props.activeStatusFilter,
    filters: props.filters?.map(({ value, options }) => ({ value, options })),
    ownershipFilter: props.ownershipFilter
  });

  const currFilterProps = useMemo<FilterComp>(
    () => ({
      activeStatusFilter: props.activeStatusFilter,
      filters: props.filters?.map(({ value, options }) => ({ value, options })),
      ownershipFilter: props.ownershipFilter
    }),
    [props.activeStatusFilter, props.filters, props.ownershipFilter]
  );

  // Update filters when props are changed
  useEffect(() => {
    if (!_.isEqual(prevFilterProps, currFilterProps)) {
      const filterChanged = buildFilters();
      setFilters(filterChanged);
    }
  }, [buildFilters, currFilterProps, prevFilterProps, props]);

  const handleSetFilters = ({ title, value }: ISetSelectedFilterProps) => {
    setFilters((prev) => {
      const newFilters = [...prev];
      const filterIndex = newFilters.findIndex((f) => f.title === title);
      if (filterIndex >= 0) {
        newFilters[filterIndex].value = value;
      }

      return newFilters;
    });
  };

  const filterFunc = useCallback((curr: T) => _.every(filters, (f) => f.matcher(curr, f.value)), [filters]);

  return [{ options: filters, setSelectedFilters: handleSetFilters }, filterFunc];
};

export const useTableSearchConfig = <T>({
  items,
  keys
}: {
  items?: T[];
  keys: string[];
}): [ITableSearchConfig, T[] | undefined] => {
  const [searchText, setSearchText] = useState('');
  const onChange = (newSearch: string | null) => setSearchText(newSearch || '');
  const fuse = useMemo(() => new Fuse(items ?? [], { keys, shouldSort: true }), [keys, items]);

  const matches = useMemo(() => {
    if (!searchText) return items;
    return fuse.search(searchText).map((result) => result.item);
  }, [fuse, searchText, items]);

  return [{ onChange, value: searchText }, matches];
};

export const useTableSelect = <T extends Identifiable>({
  models,
  rows
}: {
  models?: T[];
  rows?: ITableRowValues[] | null;
}) => {
  const [identifiableSelected, setIdentifiableSelected] = useState<IdentifiableSelected[] | null>(null);

  // TODO: Want to test this with pagination
  useEffect(
    () =>
      setIdentifiableSelected((s) => {
        const newSelected =
          rows?.map(({ rowId }) => ({
            isSelected: !!s?.find((sr) => sr.rowId && rowId && sr.rowId === rowId && sr.isSelected),
            rowId
          })) ?? null;

        return newSelected;
      }),
    [rows]
  );

  const clearSelected = () => setIdentifiableSelected((s) => s?.map((sr) => ({ ...sr, isSelected: false })) ?? null);

  const onSelect = (index: number, isSelected?: boolean) =>
    setIdentifiableSelected((prev) => {
      if (!prev) return null;
      const newSelected = [...prev];
      const prevSelectedAtIndex = prev[index];
      newSelected[index] =
        isSelected !== undefined
          ? { ...prevSelectedAtIndex, isSelected }
          : { ...prevSelectedAtIndex, isSelected: !newSelected[index] };
      return newSelected;
    });

  const onSelectAll = (v: boolean) =>
    setIdentifiableSelected((s) => {
      if (s) return s.map((sv) => ({ ...sv, isSelected: v }));
      return null;
    });

  const getSelectedModels = useCallback(() => {
    if (identifiableSelected && models) {
      const selectedModels: T[] = [];
      identifiableSelected.forEach((s) => {
        if (s.isSelected) {
          const client = models.find(({ _id }) => _id === s.rowId);
          if (client) selectedModels.push(client);
          else console.warn('Failed to find object matching selected table row ID. Should never happen.');
        }
      });

      return selectedModels;
    }
  }, [identifiableSelected, models]);

  const selected = useMemo(() => identifiableSelected?.map(({ isSelected }) => isSelected), [identifiableSelected]);

  return { clearSelected, getSelectedModels, onSelect, onSelectAll, selected };
};
