import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from '@auth0/auth0-react';

// Services
import { useRegisterUser } from '../../../domains/users/user.service';
import { ICompany } from '../../../../lib/types';

export interface ICompanyValidationProps {
  ignoreCompanyValidation?: boolean;
}

interface IForceRegisterPrompts extends ICompanyValidationProps {
  authUser: User;
  company?: ICompany | null;
  companiesLoading: boolean;
  isRegistered: boolean;
}

export const ForceRegisterPrompts: React.FC<IForceRegisterPrompts> = ({
  authUser,
  company,
  companiesLoading,
  ignoreCompanyValidation,
  isRegistered
}) => {
  const [isRegistering, setRegistering] = useState(false);
  const { registerUser } = useRegisterUser({ authId: authUser.sub });

  // Register user validations
  useEffect(() => {
    if (!isRegistered && !isRegistering) {
      const register = async () => {
        setRegistering(true);
        await registerUser();
      };

      register();
    }
  }, [isRegistered, isRegistering, registerUser]);

  // Register company validations
  if (!ignoreCompanyValidation && isRegistered && !companiesLoading && !company?.completedOnboarding)
    return <Navigate to="/onboarding" />;

  return <></>;
};
