import { Button, ButtonSize, ButtonVariant } from '../../button/button.component';

interface IFormSubmitButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onPress?: () => void;
  size?: ButtonSize;
  text: string;
  type?: 'submit' | 'button';
  variant?: ButtonVariant;
  icon?: React.ReactNode;
}

export const FormButton = ({
  disabled,
  loading,
  onPress,
  icon,
  size,
  text,
  type = 'submit',
  variant = 'primary'
}: IFormSubmitButtonProps) => {
  return (
    <Button
      disabled={disabled}
      icon={icon}
      loading={loading}
      onClick={onPress}
      size={size}
      text={text}
      type={type}
      variant={variant}
    />
  );
};
