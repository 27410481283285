import { useCallback, useState } from 'react';
import { Tag } from '../../_core/tag.component';
import { inputClasses, inputClassesLineHeightStr } from '../_core/editor.const';

interface IRadioOptionsInputProps {
  initialOptions?: string[];
  previewLabelPrefix?: string;
  tagClass: string;
}

export const RadioOptionsInput = ({
  initialOptions = [],
  previewLabelPrefix = 'Add',
  tagClass
}: IRadioOptionsInputProps) => {
  const [currInputValue, setCurrInputValue] = useState('');
  const [options, setOptions] = useState<string[]>(initialOptions);

  const handleEnter = useCallback(() => {
    if (currInputValue) {
      if (!options.includes(currInputValue)) setOptions([...options, currInputValue]);
      setCurrInputValue('');
    }
  }, [currInputValue, options]);

  const handleRemove = useCallback(
    (tag: string) => {
      setOptions(options.filter((t) => t !== tag));
    },
    [options, setOptions]
  );

  return (
    <>
      <div className="w-full">
        <input
          className={`${inputClasses.join(' ')} w-full`}
          onChange={(e) => setCurrInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEnter();
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          placeholder={'Add options'}
          type="text"
          value={currInputValue}
        />
        <div className={`absolute top-0 right-0 ${inputClassesLineHeightStr}`}>
          {options.map((tag, i) => (
            <Tag key={'tag-' + tag + i} onRemove={() => handleRemove(tag)} tag={tag} tagClass={tagClass} size="small" />
          ))}
        </div>
      </div>
      <div className={currInputValue ? 'block' : 'hidden'}>
        <div className="absolute z-30 mt-2">
          {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
          <div className="py-1 text-sm bg-white rounded shadow-lg border border-gray-300">
            <a
              className="block py-1 px-5 cursor-pointer hover:bg-indigo-600 hover:text-white"
              onClick={() => handleEnter()}
            >
              {!!previewLabelPrefix && previewLabelPrefix} "<span className="font-semibold">{currInputValue}</span>"
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
