export const OnboardingNavIcon = ({
  active,
  subtitle,
  title,
  Icon
}: {
  active?: boolean;
  subtitle: string;
  title: string;
  Icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
}) => (
  <div className="flex gap-4 mb-8">
    <div>
      <div className="border border-gray-100 bg-white p-2 rounded-md">
        <Icon className={`h-8 w bg-white-8 text-gray-${active ? '500' : '300'}`} />
      </div>
      <div className="border-l border-gray-100 block" />
    </div>
    <div className="pr-4">
      <h2 className={`text-md font-bold text-gray-${active ? '700' : '400'}`}>{title}</h2>
      <p className={`text-xs text-gray-${active ? '600' : '400'}`}>{subtitle}</p>
    </div>
  </div>
);
