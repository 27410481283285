import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { useFetchCurrUser } from '../../users/user.service';

export interface IUpdateRequestTypeFormValues {
  isGlobal: boolean;
  type: string;
}

interface IUpdateRequestTypeFormProps extends IRIFormProps<IUpdateRequestTypeFormValues> {
  isGlobal: boolean;
  type: string;
}

export const UpdateRequestTypeForm = ({ isGlobal, type, onSubmit }: IUpdateRequestTypeFormProps) => {
  const { isGlobalAdmin } = useFetchCurrUser();

  const validateForm = (values: IUpdateRequestTypeFormValues) => {
    const errors: FormikErrors<IUpdateRequestTypeFormValues> = {};

    if (!values.type.trim()) {
      errors.type = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ isGlobal, type } as IUpdateRequestTypeFormValues}
      onSubmit={(values) => onSubmit({ ...values, type: values.type.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IUpdateRequestTypeFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="type"
            name="type"
            label="Type"
            placeholder="Edit request type"
            value={props.values.type}
          />
          {!!isGlobalAdmin && (
            <FormInputWithLabel
              id="isGlobal"
              name="isGlobal"
              label="Globally Available"
              type="checkbox"
              value={props.values.isGlobal}
            />
          )}
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
