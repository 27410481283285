import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';
import { DetailBox } from './details-box.component';
import { DetailItem } from './details-item.component';
import { DetailItemProps } from './details.types';
import { Button } from '../button/button.component';
import { cloneElement } from 'react';

export const ProfileDetailsBox = ({
  actionItems,
  detailItems,
  onBack,
  title
}: {
  actionItems?: JSX.Element[];
  detailItems: DetailItemProps[];
  onBack?: () => void;
  title: string;
}) => (
  <>
    <div className="w-full py-2 flex">
      {!!onBack && (
        <Button
          icon={<ArrowLongLeftIcon className="w-6 h-6 mr-2" />}
          onClick={onBack}
          text="Back"
          type="button"
          variant="text"
          size="medium"
        />
      )}
      <h2 className="font-bold text-2xl tracking-tight w-full">{title}</h2>
    </div>
    <DetailBox noPaddingX topBorder>
      <DetailBox stretch stacked>
        {detailItems.map(({ label, value, placeholder }) => (
          <DetailItem key={'profile-detail-item-' + label} label={label} value={value} placeholder={placeholder} />
        ))}
      </DetailBox>
      {!!actionItems?.length && (
        <DetailBox stretch endAligned>
          {actionItems.map((item, i) => cloneElement(item, { key: 'action-' + i }))}
        </DetailBox>
      )}
    </DetailBox>
  </>
);
