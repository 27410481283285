import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateClient } from '../client.service';
import { IUpdateClientFormValues, UpdateClientForm } from './update-client-form.component';
import { IClient } from '../../../../lib/types';
import { logError, sleep } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { Loader } from '../../../_core/loader.component';
import { useCompanyLocations } from '../../locations/location.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { ICloseableDialogProps } from '../../../_core/core.types';

interface IUpdateClientContainerProps extends ICloseableDialogProps {
  client: IClient;
}

export const UpdateClientContainer = ({ client, close }: IUpdateClientContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();
  const { updateClient } = useUpdateClient();

  const handleUpdate = async ({ name, department, location }: IUpdateClientFormValues) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateClient({
        _id: client._id,
        name,
        department: department.map(({ value }) => value),
        location: location.map(({ value }) => value)
      });
      setSubmitError(null);
      setSuccess('Successfully updated contact!');
      sleep(250).then(close);
    } catch (err) {
      const errMsg = 'Unable to update contact.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Contact"
    >
      {!departments || !locations ? (
        <Loader />
      ) : (
        <UpdateClientForm departments={departments} locations={locations} client={client} onSubmit={handleUpdate} />
      )}
    </FormContainer>
  );
};
