import { Loader } from '../../_core/loader.component';
import { ICompanyOverview, IUser } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { useMemo, useState } from 'react';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { Tabs } from '../../_core/tabs.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { TableWithActions } from '../../_core/table/table-with-actions.component';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { FetchResult } from '@apollo/client';
import { ISetActiveCompanyProps } from '../../domains/users/user.types';
import { UpdateUserContainer } from '../../domains/users/update/update-user-form.container';
import { showError } from '../../../lib/utils';
import { useTableFilterConfig } from '../../_core/table/table-utils';
import { ProfileDetailsBox } from '../../_core/details/profile-details-box.component';
import { DetailItemProps } from '../../_core/details/details.types';

interface IProfilePageProps {
  activeCompanyId?: string;
  companies?: ICompanyOverview[];
  loadingCompanies: boolean;

  isGlobalAdmin?: boolean;
  user?: IUser;
  loadingUser: boolean;

  setActiveCompany: (_: string) => Promise<FetchResult<ISetActiveCompanyProps>>;
  settingActiveCompany: boolean;
}

export const ProfilePage: React.FC<IProfilePageProps> = ({
  activeCompanyId,
  companies,
  loadingCompanies,
  isGlobalAdmin,
  user,
  loadingUser,
  setActiveCompany,
  settingActiveCompany
}) => {
  const navigate = useNavigate();

  const [updateUser, setUpdateUser] = useState(false);

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });
  const filtered = useMemo(() => companies?.filter(filterFunc), [filterFunc, companies]);

  const detailItems = useMemo(() => {
    const items: DetailItemProps[] = [
      { label: 'Name', value: user?.name },
      { label: 'Email', value: user?.email }
    ];

    if (isGlobalAdmin) items.push({ label: 'Is Global Admin' });

    return items;
  }, [isGlobalAdmin, user]);

  if (loadingUser || !user)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <ProfileDetailsBox
        actionItems={[
          <Button size="xl" onClick={() => setUpdateUser(true)}>
            Edit User
          </Button>
        ]}
        detailItems={detailItems}
        title="Personal Profile"
      />
      <div className="mt-4 flex flex-1 w-full pb-2">
        <Tabs
          tabs={[
            {
              title: 'Associated Organizations',
              content: (
                <TableWithActions
                  bordered
                  loading={loadingCompanies}
                  headers={['Name', 'Status', '']}
                  values={
                    filtered?.map(({ _id: rowId, name, deletedAt }) => ({
                      rowId,
                      values: [
                        { children: name },
                        { children: activeStatus(!deletedAt) },
                        {
                          children:
                            activeCompanyId === rowId ? (
                              <div className="h-9 flex items-center">
                                <span className="font-semibold text-primary">Selected</span>
                              </div>
                            ) : (
                              <Button
                                loading={settingActiveCompany}
                                text="Select"
                                size="large"
                                onClick={() => {
                                  setActiveCompany(rowId)
                                    .then(() => navigate(ROUTE_PATHS.FIRM_PROFILE))
                                    .catch((err) => showError('Unable to change active organization', err));
                                }}
                              />
                            )
                        }
                      ]
                    })) ?? []
                  }
                  filterConfig={filterConfig}
                />
              )
            }
          ]}
        />
      </div>
      <RIDialog open={updateUser} setOpen={setUpdateUser}>
        <UpdateUserContainer name={user.name} close={() => setUpdateUser(false)} />
      </RIDialog>
    </>
  );
};
