import { AcceptInviteForm, IAcceptInviteFormValues } from './accept-invite-form.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useAcceptInvite, useInvite } from '../invite.service';
import { FetchInviteErrors } from '../invite.types';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';
import { useAcceptClientInvite } from '../../client-invite/client-invite.service';
import { useFetchCurrUser } from '../../users/user.service';
import { useAuth0 } from '@auth0/auth0-react';
import { ROUTE_PATHS } from '../../../../_routes';
import { useLogout } from '../../auth/auth.service';
import { useCallback, useMemo } from 'react';
import { INVITE_STATUS } from '../../../../lib/types';

interface IAcceptInviteContainer extends IFormWithStepperProps {
  code?: string;
  type?: InviteType;
}

export const AcceptInviteNestedContainer = ({ code, type, onBack, onError, onSuccess }: IAcceptInviteContainer) => {
  const { acceptInvite } = useAcceptInvite();
  const { acceptClientInvite } = useAcceptClientInvite();

  const { loginWithRedirect } = useAuth0();
  const { handleLogout } = useLogout();
  const { invite } = useInvite(code ?? '');
  const { user } = useFetchCurrUser();

  const loginAndAccept = useCallback(async () => {
    return loginWithRedirect({ appState: { returnTo: ROUTE_PATHS.JOIN + '/' + code } });
  }, [code, loginWithRedirect]);

  const isWrongUser = useMemo(() => !!invite && !!user && invite.email !== user?.email, [invite, user]);

  const handleAccept = async ({ code: selectedCode, type: selectedType }: IAcceptInviteFormValues) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (!user) await loginAndAccept();
      else if (isWrongUser) {
        await handleLogout({
          onFinish: () => {
            return loginAndAccept();
          }
        });
      } else {
        if (selectedType === InviteType.CLIENT) await acceptClientInvite({ code: selectedCode });
        else if (selectedType === InviteType.STAFF) await acceptInvite({ code: selectedCode });
        else {
          onError?.('Invalid invite type.');
          return;
        }

        onSuccess?.('Successfully accepted invite.');
      }
    } catch (err) {
      const errorMessage = (err as Error).message;

      let msg = 'Failed to accept invite.';
      if (errorMessage === FetchInviteErrors.COMPANY_DELETED) {
        msg = 'Organization has been deleted.';
      } else if (errorMessage === FetchInviteErrors.DOES_NOT_EXIST) {
        msg = 'Invite does not exist.';
      } else if (errorMessage === FetchInviteErrors.EXPIRED) {
        msg = 'Invite expired.';
      }

      logError(msg, errorMessage);
      onError?.(msg);
    }
  };

  if (invite?.status !== INVITE_STATUS.PENDING || invite?.isExpired)
    return (
      <div className="flex flex-1 justify-center text-center">
        <h2 className="mb-4 text-error">
          Invite is no longer valid.
          <br />
          Please request a new invite.
        </h2>
      </div>
    );

  return (
    <AcceptInviteForm
      code={code}
      invite={invite}
      isWrongUser={isWrongUser}
      type={type}
      user={user}
      onSubmit={handleAccept}
      onBack={onBack}
    />
  );
};
