import { LowlightText } from '../typography';
import { ITableCellProps } from './table.types';
import { stickyTableCellClass } from './table-consts';

// TODO: Can't get box shadow to show up for TH elements for some reason
export const TableCell = ({ children, header, rowClass }: ITableCellProps) => {
  const childrenIsString = typeof children === 'string';
  const childComponents = childrenIsString ? (
    <LowlightText className={`${header ? '!whitespace-normal' : ''} ${rowClass?.content ?? ''}`}>
      {children}
    </LowlightText>
  ) : (
    children
  );

  if (header)
    return (
      <th scope="col" className={`px-2 py-2 ${stickyTableCellClass} ${rowClass?.cell ?? ''}`}>
        {childComponents}
      </th>
    );

  return <td className={`px-2 py-1 ${rowClass?.cell ?? ''}`}>{childComponents}</td>;
};
