// Interfaces and Types

// Client Enums must match the keys of the Server enums, instead of the text values
export enum ACTIVE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum INVITE_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED'
}

export enum PLAN_NAME {
  PERSONAL = 'PERSONAL',
  FIRM = 'FIRM'
}

export enum ROLE {
  COMPANY_OWNER = 'COMPANY_OWNER',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_STAFF = 'COMPANY_STAFF'
}

export enum TEMPLATE_TYPE {
  BLOCK = 'BLOCK',
  REQUEST = 'REQUEST',
  SECTION = 'SECTION'
}

export enum REQUEST_STATUS {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  ARCHIVED_DRAFT = 'ARCHIVED - DRAFT'
}

export enum REQUEST_NOTIFY_OPTION {
  ALL = 'ALL',
  STAFF = 'STAFF',
  CLIENTS = 'CLIENTS',
  ASSIGNEE = 'ASSIGNEE'
}

export const requestNotifyOptionMultiselectLabels = {
  [REQUEST_NOTIFY_OPTION.CLIENTS]: 'All Contacts',
  [REQUEST_NOTIFY_OPTION.STAFF]: 'All Team Members'
};

export const ROLE_LABELS = {
  [ROLE.COMPANY_STAFF]: 'Team Member',
  [ROLE.COMPANY_ADMIN]: 'Admin',
  [ROLE.COMPANY_OWNER]: 'Owner'
};

export const ROLES_FOR_LABEL = Object.entries(ROLE).reduce((acc: Record<string, ROLE>, [key, value]) => {
  acc[key] = value;
  return acc;
}, {});

export const ROLE_ORDER = Object.values(ROLE);

export const requestStatusLabels: Record<REQUEST_STATUS, string> = {
  [REQUEST_STATUS.DRAFT]: 'Draft',
  [REQUEST_STATUS.SENT]: 'Active',
  [REQUEST_STATUS.CLOSED]: 'Closed',
  [REQUEST_STATUS.ARCHIVED]: 'Archived',
  [REQUEST_STATUS.ARCHIVED_DRAFT]: 'Archived'
};

// Common Model Patterns
export interface CompanyPermissions {
  department: IDepartment[];
  location: ILocation[];
}

export interface Deletable {
  deletedAt?: Date;
}

export interface Identifiable {
  _id: string;
}

interface Mutable {
  updatedAt: Date;
}

export interface Nameable {
  name?: string;
}

export interface Emailable {
  email: string;
}

export interface OptionalCompany {
  company?: ICompany;
}

export interface InviteVariant extends Emailable {
  expiresAt: Date;
  isExpired: boolean;
  status: INVITE_STATUS;
  sentAt: Date;
}

interface UserVariant {
  user: IUserOverview;
  company?: ICompanyOverview;
}

// Data Models
export interface IUserOverview extends Identifiable, Deletable, Emailable, Nameable {}
export interface IUser extends IUserOverview {
  authId?: string;
  isAdmin: boolean;
  phone?: string;
  settings: IUserSettings;
}

export interface IUserSettings {
  activeCompanyId: string | null;
}

export interface IRequestType extends Identifiable, Deletable {
  type: string;
}

export interface ICompanySettings {
  requestTypes: string[];
}

export interface ICompanyOverview extends Identifiable, Deletable, Nameable {}
export interface ICompany extends ICompanyOverview {
  completedOnboarding?: boolean;
  plan: IPlan;
  settings: ICompanySettings;
}

export interface IMemberOverview extends Identifiable, Deletable, CompanyPermissions, UserVariant {
  roles: ROLE[];
}

export interface IMember extends IMemberOverview {
  user: IUser;
}

export interface IDepartment extends Identifiable, Deletable, Nameable {}
export interface ILocation extends Identifiable, Deletable, Nameable {}

export interface IInviteOverview extends Identifiable, Deletable, CompanyPermissions, InviteVariant {
  roles: ROLE[];
}

export interface IInvite extends IInviteOverview {
  code: string;
  company: ICompanyOverview;
  sentBy?: IUserOverview;
}

export interface IPlan {
  name: PLAN_NAME;
}

export interface IRequestBlockTemplate {
  data: JSON;
  type: string;
}

export interface IRequestBlock extends IRequestBlockTemplate {
  id: string;
}

export interface IBaseRequestProps {
  notes?: string;
}

export interface RequestCompletion {
  completedSections: number;
  totalSections: number;
  percentage: number;
}

export interface IRequestOverview extends Identifiable, Nameable, Mutable, Deletable {
  assignedTo?: IClientOverview;
  completion: RequestCompletion;
  createdBy: Nameable & Identifiable;
  deadline?: Date;
  entity?: Nameable;
  requestType?: IRequestType;
  sentAt?: Date;
  status?: REQUEST_STATUS;
}

export interface IRequest extends IRequestOverview, IBaseRequestProps {
  assignedTo?: IClient;
  blocks?: IRequestBlock[];
  clientEditorIds?: IClient[];
  clientViewerIds?: IClient[];
  company?: ICompanyOverview;
  completion: RequestCompletion;
  createdBy: IUser;
  deadline?: Date;
  deletedAt?: Date;
  entity?: IEntity;
  requestType?: IRequestType;
  sentAt?: Date;
  staffEditorIds?: IMemberOverview[];
  staffViewerIds?: IMemberOverview[];
  status?: REQUEST_STATUS;
  time?: number;
  version?: string;
}

export interface ITemplateBaseDetails extends Identifiable, Deletable, OptionalCompany {
  blockType?: string;
  description?: string;
  title: string;
  type: TEMPLATE_TYPE;
  version: string;
}

export interface ITemplate extends ITemplateBaseDetails {
  blocks: IRequestBlockTemplate[];
  description?: string;
  title: string;
  type: TEMPLATE_TYPE;
  version: string;
}

export interface IClientOverview extends Identifiable, Nameable, Deletable {
  user: IUserOverview;
}

export interface IClient extends Identifiable, Deletable, CompanyPermissions, UserVariant, Nameable {
  // These are client only fields, used as a tool to quickly associate Clients with their "associated" Entity Members
  entityMemberId?: string;
  entityDeletedAt?: Date;
}

export interface IClientInvite extends Identifiable, Deletable, CompanyPermissions, InviteVariant {
  entity?: IEntity;
}

export interface IEntityOverview extends Identifiable, Deletable, Nameable {
  status: ACTIVE_STATUS;
}

export interface IEntity extends IEntityOverview, CompanyPermissions {
  mainContact?: IUserOverview;
  status: ACTIVE_STATUS;
}

export interface IEntityMember extends Identifiable, Deletable {
  entity: IEntity;
  user: IUser;
}

export interface IEntityWithMembers {
  entity: IEntity;
  members: IEntityMember[];
}

export interface IAsset extends Identifiable, Deletable {
  file: string;
  name: string;
  url: string;
}

export interface IClientInviteSendResult {
  client: IClient;
  entityMember?: IEntityMember;
  sentAt?: Date;
}

export interface ISendClientProps extends Emailable {
  department?: string[];
  entityId?: string;
  location?: string[];
  name?: string;
}

export interface IRequestTokenParams {
  requestToken?: string;
}

export interface IAccessToken extends Identifiable, Deletable, UserVariant {
  expiryDate?: Date;
}
