// Components
import { Button } from '../../_core/button/button.component';

interface ILandingPage {
  isLoading: boolean;
  onSignIn: () => void;
}

export const LandingPage: React.FC<ILandingPage> = ({ isLoading, onSignIn }) => {
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col items-center">
        <div className="px-4 md:px-0 sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-2 mb-2 text-center text-xl font-bold leading-9 tracking-tight text-slate-800">
            Get started with RequestIQ
          </h2>
          <p className="text-slate-600 text-center text-sm mb-4">
            Ask questions and collect documents all in one place
          </p>
          <div className="flex flex-col gap-4">
            <Button text="Sign In or Sign Up" onClick={onSignIn} loading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};
