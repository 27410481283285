import { useMemo, useState } from 'react';
import { ContactsTableContainer } from '../../domains/contacts/contacts-table/contacts-table.container';
import { useCurrMember } from '../../domains/member/member.service';
import { EntityTableContainer } from '../DashboardPage/entity-table/entity-table.container';
import { ITableModeConfig } from '../../_core/table/table-with-actions.component';
import { useSearchParams } from 'react-router-dom';
import { ContactsPageTableMode } from './contacts-page.types';

export const ContactsPage = () => {
  const [params, setSearchParams] = useSearchParams();

  const { isAdmin } = useCurrMember();

  const [mode, setMode] = useState<string>(
    params.has('type') && params.get('type') === ContactsPageTableMode.Entity.toLowerCase()
      ? ContactsPageTableMode.Entity
      : ContactsPageTableMode.Client
  );

  const modeConfig = useMemo<ITableModeConfig>(
    () => ({
      onChange: (m) => {
        setSearchParams({ type: m.toLowerCase() });
        setMode(m);
      },
      options: Object.values(ContactsPageTableMode),
      value: mode
    }),
    [mode, setSearchParams]
  );

  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      {mode === ContactsPageTableMode.Entity && <EntityTableContainer isAdmin={isAdmin} modeConfig={modeConfig} />}
      {mode === ContactsPageTableMode.Client && <ContactsTableContainer isAdmin={isAdmin} modeConfig={modeConfig} />}
    </div>
  );
};
