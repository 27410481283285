import { useClients } from '../../clients/client.service';
import { ContactsTableNestedContainer } from './contacts-table.nested.container';
import { Loader } from '../../../_core/loader.component';
import { useCurrCompany } from '../../companies/company.service';
import { IAdminProps } from '../../../_core/core.types';
import { ITableModeConfig } from '../../../_core/table/table-with-actions.component';
import { useEntitiesMembers } from '../../entity-members/entity-members.service';
import { useEntityOverviews } from '../../entities/entity.service';

interface IContactsTableContainer extends IAdminProps {
  modeConfig?: ITableModeConfig;
}

export const ContactsTableContainer: React.FC<IContactsTableContainer> = ({ isAdmin, modeConfig }) => {
  const { clients } = useClients();
  const { isPersonal } = useCurrCompany();
  const { entities } = useEntityOverviews();
  const { entitiesMembers } = useEntitiesMembers({ entityIds: entities?.map((e) => e._id) ?? [] });

  if (!clients) return <Loader />;

  return (
    <ContactsTableNestedContainer
      isAdmin={isAdmin}
      isPersonalCompany={isPersonal}
      clients={clients}
      entitiesWithMembers={entitiesMembers}
      modeConfig={modeConfig}
    />
  );
};
