import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { ISendInvitesContainerProps, SendInvitesNestedContainer } from './send-invites-form-nested.container';
import { sleep } from '../../../../lib/utils';
import { ICloseableDialogProps } from '../../../_core/core.types';

export const SendInvitesContainer = ({
  isClientInvite,
  isPersonalCompany,
  entity,
  close
}: ISendInvitesContainerProps & ICloseableDialogProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title={isClientInvite ? 'Invite Contacts' : 'Invite Team Members'}
    >
      <SendInvitesNestedContainer
        onError={(e) => setSubmitError(e ?? null)}
        onSuccess={(s) => {
          setSuccess(s ?? null);
          if (s) sleep(250).then(close);
        }}
        isClientInvite={isClientInvite}
        isPersonalCompany={isPersonalCompany}
        entity={entity}
      />
    </FormContainer>
  );
};
