import { gql } from '@apollo/client';
import { USER_FRAGMENT, USER_OVERVIEW_FRAGMENT } from '../users/user.queries';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { LOCATION_FRAGMENT } from '../locations/location.queries';

export const MEMBER_OVERVIEW_FRAGMENT = gql`
  fragment MemberOverview on Member {
    _id
    user {
      ...UserOverview
    }
    roles
    deletedAt
    department {
      ...DepartmentDetails
    }
    location {
      ...LocationDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_OVERVIEW_FRAGMENT}
`;

export const MEMBER_FRAGMENT = gql`
  fragment MemberDetails on Member {
    _id
    user {
      ...UserDetails
    }
    roles
    deletedAt
    department {
      ...DepartmentDetails
    }
    location {
      ...LocationDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GET_MEMBERS = gql`
  query GetMembers {
    members {
      ...MemberOverview
    }
  }

  ${MEMBER_OVERVIEW_FRAGMENT}
`;

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($_id: ID!) {
    removeMember(_id: $_id) {
      ...MemberOverview
    }
  }

  ${MEMBER_OVERVIEW_FRAGMENT}
`;

export const REMOVE_SELF = gql`
  mutation RemoveSelf {
    removeSelf {
      ...MemberDetails
    }
  }

  ${MEMBER_FRAGMENT}
`;

export const RESTORE_MEMBER = gql`
  mutation RestoreMember($_id: ID!) {
    restoreMember(_id: $_id) {
      ...MemberOverview
    }
  }

  ${MEMBER_OVERVIEW_FRAGMENT}
`;

export const UPDATE_MEMBERS = gql`
  mutation UpdateMembers($department: [ID!], $ids: [ID!]!, $location: [ID!], $roles: [ROLE!]) {
    updateMembers(department: $department, ids: $ids, location: $location, roles: $roles) {
      ...MemberOverview
    }
  }

  ${MEMBER_OVERVIEW_FRAGMENT}
`;
