import { Button } from '../../../_core/button/button.component';
import { IRequestOverview, REQUEST_STATUS, requestStatusLabels } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { RequestTable, IRequestTableRowValues } from './request-table.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';

import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../../domains/request/request-action-button';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/table-utils';
import { LowlightTextWithSubtitle } from '../../../_core/typography';

enum RequestTableType {
  ACTIVE = 'Inbox',
  WORKSPACE = 'Drafts'
}

export interface IRequestTableContainer extends IAdminProps, IPersonalCompanyProps {
  draftsMode?: boolean;
}

interface IRequestTableNestedContainerProps extends IRequestTableContainer {
  requests?: IRequestOverview[];
}

export const RequestTableNestedContainer: React.FC<IRequestTableNestedContainerProps> = ({ requests, draftsMode }) => {
  const { user } = useFetchCurrUser();

  const [createRequest, setCreateRequest] = useState(false);

  const [mode, setMode] = useState<string>(draftsMode ? RequestTableType.WORKSPACE : RequestTableType.ACTIVE);
  const showingDrafts = useMemo(() => mode === RequestTableType.WORKSPACE, [mode]);

  const [searchConfig, searchMatches] = useTableSearchConfig({
    items: requests,
    keys: [
      'name',
      'company',
      'requestType',
      'company.name',
      'assignedTo.name',
      'deadline',
      'sentAt',
      'status',
      'createdBy.name'
    ]
  });
  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: showingDrafts
          ? [REQUEST_STATUS.DRAFT, REQUEST_STATUS.ARCHIVED_DRAFT]
          : [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED, REQUEST_STATUS.ARCHIVED],
        value: showingDrafts ? [REQUEST_STATUS.DRAFT] : [REQUEST_STATUS.SENT],
        matcher: (curr: IRequestOverview, filterValue: string[]) => {
          const isDraft = !curr.status || curr.status === REQUEST_STATUS.DRAFT;
          if (!filterValue.length) return showingDrafts ? isDraft : !isDraft;

          if (curr.deletedAt)
            return isDraft
              ? filterValue.includes(REQUEST_STATUS.ARCHIVED_DRAFT)
              : filterValue.includes(REQUEST_STATUS.ARCHIVED);

          return filterValue.includes(curr.status ?? REQUEST_STATUS.DRAFT);
        },
        labeler: (value) => (value in requestStatusLabels ? requestStatusLabels[value as REQUEST_STATUS] : undefined)
      }
    ]
  });

  const requestRows = useMemo(() => {
    if (!searchMatches) return [];
    const sortable = [...searchMatches];
    return sortable
      .filter(filterFunc)
      .sort((a, b) => {
        if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
        if (a.deletedAt && !b.deletedAt) return 1;
        if (!a.deletedAt && b.deletedAt) return -1;
        return a.updatedAt < b.updatedAt ? 1 : -1;
      })
      .map((request, i) => {
        const row: IRequestTableRowValues = {
          request,
          values: [
            {
              children: (
                <LowlightTextWithSubtitle
                  centered
                  subtitle={
                    new Date(request.updatedAt).toDateString() === new Date().toDateString()
                      ? new Date(request.updatedAt).toLocaleTimeString('en-CA', {
                          hour: 'numeric',
                          minute: '2-digit'
                        })
                      : undefined
                  }
                >
                  {new Date(request.updatedAt).toLocaleDateString('en-CA', {
                    year: '2-digit',
                    day: 'numeric',
                    month: 'numeric'
                  })}
                </LowlightTextWithSubtitle>
              )
            }
          ]
        };

        if (!showingDrafts)
          row.values.push({
            children: request.sentAt
              ? new Date(request.sentAt).toLocaleDateString('en-CA', {
                  year: '2-digit',
                  day: 'numeric',
                  month: 'numeric'
                })
              : ''
          });

        row.values.push(
          {
            children: (
              <LowlightTextWithSubtitle
                subtitle={
                  request.assignedTo?.name ?? request.assignedTo?.user.name ? request.assignedTo?.user.email : undefined
                }
              >
                {request.assignedTo?.name ?? request.assignedTo?.user.name ?? request.assignedTo?.user.email ?? ''}
              </LowlightTextWithSubtitle>
            )
          },
          { children: request.entity?.name ?? '' },
          { children: request.name },
          { children: request.requestType?.type ?? '' },
          {
            children: request.deadline
              ? new Date(request.deadline).toLocaleDateString('en-CA', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit'
                })
              : ''
          }
        );

        if (!showingDrafts)
          row.values.push({
            children: (
              <div className="text-xs whitespace-nowrap">
                <span>{Math.round(request.completion.percentage * 100)}%</span>
                <span className="ml-2">
                  {request.completion.completedSections}/{request.completion.totalSections}
                </span>
              </div>
            )
          });

        const isDraft = !request.status || request.status === REQUEST_STATUS.DRAFT;
        row.values.push(
          {
            children: (
              <p
                className={`text-xs overflow-hidden whitespace-nowrap text-ellipsis font-bold ${
                  user?._id === request.createdBy._id ? 'text-green-600' : ''
                }`}
              >
                {request.createdBy.name}
              </p>
            )
          },
          {
            children:
              requestStatusLabels[
                request.deletedAt
                  ? isDraft
                    ? REQUEST_STATUS.ARCHIVED_DRAFT
                    : REQUEST_STATUS.ARCHIVED
                  : request.status ?? REQUEST_STATUS.DRAFT
              ]
          },
          {
            children: (
              <RequestActionButton
                request={request}
                last={searchMatches.length > 3 && i === searchMatches.length - 1}
              />
            )
          }
        );

        return row;
      });
  }, [showingDrafts, filterFunc, searchMatches, user?._id]);

  if (!requestRows) return null;

  return (
    <>
      <RequestTable
        filterConfig={filterConfig}
        requests={requestRows}
        searchConfig={searchConfig}
        draftsMode={showingDrafts}
        modeConfig={{
          onChange: (selected) => {
            setMode(selected);
            if (selected === RequestTableType.ACTIVE)
              filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.SENT] });
            else filterConfig.setSelectedFilters({ title: TableFilterKeys.STATUS, value: [REQUEST_STATUS.DRAFT] });
          },
          options: Object.values(RequestTableType),
          value: mode
        }}
      >
        <Button onClick={() => setCreateRequest(true)} text="Create Request" size="large" className="mt-1" />
      </RequestTable>
      <RIDialog open={createRequest} setOpen={setCreateRequest}>
        <EditRequestContainer />
      </RIDialog>
    </>
  );
};
