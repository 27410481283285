import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ICreateDepartmentProps } from '../department.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface ICreateDepartmentsFormValues {
  names: string[];
}

interface ICreateDepartmentsFormProps extends IRIFormProps<ICreateDepartmentProps> {
  ignoreDefaults?: boolean;
}

export const CreateDepartmentsForm = ({ ignoreDefaults, onBack, onSkip, onSubmit }: ICreateDepartmentsFormProps) => {
  const validateForm = (values: ICreateDepartmentsFormValues) => {
    const errors: FormikErrors<ICreateDepartmentsFormValues> = {};

    if (!values.names.filter((name) => !!name.trim().length).length) {
      errors.names = 'At least one value required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ names: ignoreDefaults ? [] : ['Tax'] } as ICreateDepartmentsFormValues}
      onSubmit={(values) => onSubmit({ names: values.names.filter((name) => name.trim()) })}
      validate={validateForm}
    >
      {(props: FormikProps<ICreateDepartmentsFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInfo
            title="Please set up departments:"
            messages={[
              "Departments allow you to organize your organization's contacts by departments.",
              'For example, Audit, Tax, Tax-T1s, Advisory, ect.',
              'If you are not sure how you want to organize things yet you can skip this step.'
            ]}
          />
          <FormInputWithLabel
            id="names"
            name="names"
            label="Department Names"
            placeholder="Add department name"
            value={props.values.names}
            type="tags"
            tagsProps={{ previewLabelPrefix: 'Add department' }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Next" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
