import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../users/user.queries';
import { REQUEST_TYPE_FRAGMENT } from '../request-type/request-type.queries';
import { CLIENT_FRAGMENT } from '../clients/client.queries';
import { MEMBER_FRAGMENT } from '../member/member.queries';
import { ENTITY_FRAGMENT } from '../entities/entity.queries';

const REQUEST_OVERVIEW_FRAGMENT = gql`
  fragment RequestOverview on Request {
    _id
    assignedTo {
      name
      user {
        email
        name
      }
    }
    completion {
      completedSections
      totalSections
      percentage
    }
    createdBy {
      _id
      name
    }
    deadline
    deletedAt
    entity {
      name
    }
    name
    notes
    requestType {
      type
    }
    sentAt
    status
    updatedAt
  }
`;

const REQUEST_FRAGMENT = gql`
  fragment RequestDetails on Request {
    _id
    assignedTo {
      ...ClientDetails
    }
    blocks {
      id
      data
      type
    }
    clientEditorIds {
      ...ClientDetails
    }
    clientViewerIds {
      ...ClientDetails
    }
    completion {
      completedSections
      totalSections
      percentage
    }
    createdBy {
      ...UserDetails
    }
    deadline
    deletedAt
    entity {
      ...EntityDetails
    }
    name
    notes
    requestType {
      ...RequestTypeDetails
    }
    sentAt
    staffEditorIds {
      ...MemberDetails
    }
    staffViewerIds {
      ...MemberDetails
    }
    status
    updatedAt
    version
    time
  }

  ${CLIENT_FRAGMENT}
  ${ENTITY_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${REQUEST_TYPE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const CLEAR_REQUEST = gql`
  mutation ClearRequest($_id: ID!) {
    clearRequest(_id: $_id) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const DUPLICATE_REQUEST = gql`
  mutation DuplicateRequest($_id: ID!, $clear: Boolean!) {
    duplicateRequest(_id: $_id, clear: $clear) {
      ...RequestOverview
    }
  }

  ${REQUEST_OVERVIEW_FRAGMENT}
`;

export const GET_REQUEST = gql`
  query GetRequest($_id: ID!) {
    requestById(_id: $_id) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const GET_REQUEST_WITH_TOKEN = gql`
  query GetRequestWithToken($token: String!, $requestId: String!) {
    requestByWithToken(token: $token, requestId: $requestId) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const LIST_REQUESTS_AS_STAFF = gql`
  query ListRequestsAsStaff {
    listRequestsAsStaff {
      ...RequestOverview
    }
  }

  ${REQUEST_OVERVIEW_FRAGMENT}
`;

export const CREATE_REQUEST = gql`
  mutation CreateRequest(
    $assignedTo: ID
    $blocks: [RequestBlockInput!]
    $ccClients: [ID!]
    $deadline: DateTimeISO
    $entityId: ID
    $name: String!
    $notes: String
    $requestType: ID
    $staff: [ID!]
    $time: Float
    $version: String
  ) {
    createRequest(
      assignedTo: $assignedTo
      blocks: $blocks
      ccClients: $ccClients
      deadline: $deadline
      entityId: $entityId
      name: $name
      notes: $notes
      requestType: $requestType
      staff: $staff
      time: $time
      version: $version
    ) {
      ...RequestOverview
    }
  }

  ${REQUEST_OVERVIEW_FRAGMENT}
`;

export const CREATE_REQUEST_FROM_TEMPLATE = gql`
  mutation CreateRequestFromTemplate(
    $assignedTo: ID
    $ccClients: [ID!]
    $deadline: DateTimeISO
    $entityId: ID
    $name: String!
    $notes: String
    $requestType: ID
    $staff: [ID!]
    $templateId: ID!
    $time: Float
  ) {
    createRequestFromTemplate(
      assignedTo: $assignedTo
      ccClients: $ccClients
      deadline: $deadline
      entityId: $entityId
      name: $name
      notes: $notes
      requestType: $requestType
      staff: $staff
      templateId: $templateId
      time: $time
    ) {
      ...RequestOverview
    }
  }

  ${REQUEST_OVERVIEW_FRAGMENT}
`;

export const UPDATE_REQUEST = gql`
  mutation UpdateRequest(
    $_id: ID!
    $assignedTo: ID
    $blocks: [RequestBlockInput!]
    $ccClients: [ID!]
    $deadline: DateTimeISO
    $entityId: ID
    $name: String
    $notes: String
    $requestType: ID
    $staff: [ID!]
    $status: REQUEST_STATUS
    $time: Float
    $version: String
  ) {
    updateRequest(
      _id: $_id
      assignedTo: $assignedTo
      blocks: $blocks
      ccClients: $ccClients
      deadline: $deadline
      entityId: $entityId
      name: $name
      notes: $notes
      requestType: $requestType
      staff: $staff
      status: $status
      time: $time
      version: $version
    ) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const UPDATE_REQUEST_AS_CLIENT = gql`
  mutation UpdateRequestAsClient($_id: ID!, $blocks: [RequestBlockInput!]!) {
    updateRequestAsClient(_id: $_id, blocks: $blocks) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const UPDATE_REQUEST_WITH_TOKEN = gql`
  mutation UpdateRequestWithToken($_id: ID!, $blocks: [RequestBlockInput!]!, $token: String!) {
    updateRequestWithToken(_id: $_id, blocks: $blocks, token: $token) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($_id: ID!) {
    deleteRequest(_id: $_id) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const RESTORE_REQUEST = gql`
  mutation RestoreRequest($_id: ID!) {
    restoreRequest(_id: $_id) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const SEND_REQUEST = gql`
  mutation SendRequest(
    $_id: ID!
    $notify: [REQUEST_NOTIFY_OPTION!]!
    $customMessage: String
    $replyTo: String
    $subject: String
  ) {
    sendRequest(_id: $_id, notify: $notify, customMessage: $customMessage, replyTo: $replyTo, subject: $subject) {
      ...RequestDetails
    }
  }

  ${REQUEST_FRAGMENT}
`;

export const SEND_CUSTOM_REQUEST_MESSAGE = gql`
  mutation SendCustomRequestMessage(
    $_id: ID!
    $subject: String!
    $message: String
    $notify: [REQUEST_NOTIFY_OPTION!]!
    $replyTo: String
    $to: [ID!]
  ) {
    sendCustomRequestMessage(
      _id: $_id
      message: $message
      notify: $notify
      replyTo: $replyTo
      to: $to
      subject: $subject
    )
  }
`;

export const SEND_CUSTOM_REQUEST_MESSAGE_WITH_TOKEN = gql`
  mutation SendCustomRequestMessageWithToken(
    $_id: ID!
    $subject: String!
    $message: String
    $notify: [REQUEST_NOTIFY_OPTION!]!
    $replyTo: String
    $to: [ID!]
    $token: String!
  ) {
    sendCustomRequestMessageWithToken(
      _id: $_id
      message: $message
      notify: $notify
      replyTo: $replyTo
      to: $to
      token: $token
      subject: $subject
    )
  }
`;
