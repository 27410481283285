import { IEntity } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { extractFormOptionValue } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { useCompanyLocations } from '../../locations/location.service';
import { useSendInvites } from '../invite.service';
import { ISendInvitesProps } from '../invite.types';
import { SendInvitesForm } from './send-invites-form.component';

export interface ISendInvitesContainerProps extends IPersonalCompanyProps {
  isClientInvite?: boolean;
  entity?: IEntity;
}

interface ISendInvitesNestedContainerProps extends IFormWithStepperProps, ISendInvitesContainerProps {}

export const SendInvitesNestedContainer = ({
  entity,
  isClientInvite,
  isPersonalCompany,
  onError,
  onSuccess,
  ...rest
}: ISendInvitesNestedContainerProps) => {
  const { departments, loading: loadingDepartments, called: calledDepartments } = useCompanyDepartments();
  const { locations, loading: loadingLocations, called: calledLocations } = useCompanyLocations();
  const { sendInvites } = useSendInvites();
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ emails, roles, location, department }: ISendInvitesProps) => {
    try {
      if (isClientInvite) {
        const results = await Promise.all(
          emails.map((email) =>
            sendClientInvite({
              email,
              entityId: entity?._id,
              location: location.map(extractFormOptionValue),
              department: department.map(extractFormOptionValue)
            })
          )
        );

        if (results.some((r) => !r.data?.sendClientInvite.sentAt)) {
          onError?.('We were unable to deliver some of the invites');
          return;
        }
      } else {
        const { data } = await sendInvites({
          emails,
          roles: roles?.map(extractFormOptionValue) ?? [],
          location: location.map(extractFormOptionValue),
          department: department.map(extractFormOptionValue)
        });

        onSuccess?.(
          !data?.sendInvites.length
            ? 'All recipients are already invited or are already members'
            : data?.sendInvites.length === emails.length
            ? 'Successfully sent invites!'
            : `Succeeded! Skipped sending ${
                emails.length - (data?.sendInvites.length ?? 0)
              } invites, due to member or invite already existing.`
        );
      }
    } catch (err) {
      logError('Unable to send invites', (err as Error).message);
      onError?.('Unable to send invites');
    }
  };

  if (loadingDepartments || loadingLocations || !calledDepartments || !calledLocations || !departments || !locations)
    return <Loader />;

  return (
    <SendInvitesForm
      departments={departments}
      locations={locations}
      onSubmit={handleSubmit}
      isClientInvite={!!isClientInvite}
      isClientEntityInvite={!!entity}
      isPersonalCompany={isPersonalCompany}
      {...rest}
    />
  );
};
