import { IRenderOpenToggleParams, renderOpenToggle } from './editor.utils';

// Render headers for branch and repeat section templates
export const renderSectionTemplateHeader = ({
  idPrefix,
  onOpenToggle,
  open,
  sectionElement,
  sectionIndex,
  sectionTitleText
}: {
  idPrefix: string;
  onOpenToggle: (_: { index: number; renderParams: IRenderOpenToggleParams }) => void;
  open: boolean;
  sectionElement: HTMLDivElement;
  sectionIndex: number;
  sectionTitleText: string;
}) => {
  const ids = getSectionElementIds(idPrefix, sectionIndex);

  sectionElement.id = ids.element;
  sectionElement.classList.remove('hidden');

  // Add title to section wrapper
  const sectionElementWrapper = document.createElement('div');
  sectionElementWrapper.classList.add(idPrefix);

  const sectionTitleWrapper = document.createElement('div');
  sectionTitleWrapper.id = ids.titleWrapper;
  sectionTitleWrapper.classList.add('flex', 'gap-2', 'mt-1');

  const sectionTitle = document.createElement('div');
  sectionTitle.textContent = sectionTitleText;
  sectionTitleWrapper.appendChild(sectionTitle);

  const spacer = document.createElement('div');
  spacer.classList.add('flex-1', 'border-b', 'border-black', 'self-center');
  sectionTitleWrapper.appendChild(spacer);

  // Add open toggle to section template titles
  const renderParams: IRenderOpenToggleParams = {
    open,
    openToggleId: ids.openToggle,
    parent: sectionTitleWrapper
  };
  renderParams.onOpenToggle = () => onOpenToggle({ index: sectionIndex, renderParams });
  renderOpenToggle(renderParams);

  sectionElementWrapper.appendChild(sectionTitleWrapper);

  // Add section content to wrapper
  sectionElementWrapper.appendChild(sectionElement);
  return sectionElementWrapper;
};

export const getSectionElementIds = (idPrefix: string, sectionIndex: number) => {
  const prefix = idPrefix + sectionIndex;
  return {
    element: prefix + '-section-element',
    openToggle: prefix + '-open-toggle',
    titleWrapper: prefix + '-section-title-wrapper'
  };
};
