import { IClient, IEntity } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { ISendClientInvite } from '../invite.types';
import { CreateClientInviteForm } from './create-client-invite-form.component';

export interface ICreateClientInviteContainerProps extends IPersonalCompanyProps {
  afterSuccess: (_: IClient) => void;
  email: string;
  entity?: IEntity;
}

interface ICreateClientInviteNestedContainerProps extends IFormWithStepperProps, ICreateClientInviteContainerProps {}

export const CreateClientInviteNestedContainer = ({
  afterSuccess,
  entity,
  onError,
  onSuccess,
  ...rest
}: ICreateClientInviteNestedContainerProps) => {
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ email, name }: ISendClientInvite) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      const result = await sendClientInvite({ email, name, entityId: entity?._id });
      onSuccess?.('Successfully added contact');

      if (result.data) afterSuccess(result.data?.sendClientInvite.client);
    } catch (err) {
      const errMsg = 'Unable to add contact';
      logError(errMsg, (err as Error).message);
      onError?.(errMsg);
    }

    return;
  };

  return <CreateClientInviteForm onSubmit={handleSubmit} {...rest} />;
};
