import { createRoot } from 'react-dom/client';
import { QuestionType } from '../../question/question.types';
import { inputClasses } from '../editor.const';
import { onReadonlyInputKeydown } from './input.utils';
import { AutoSizeTextArea } from '../autosizetextarea.component';

export const createAnswerInput = ({
  addOnEdit,
  disabled,
  id,
  onAnswerChange,
  options,
  placeholder = '< Enter answer >',
  readOnly,
  type,
  value,
  width = 'w-full'
}: {
  addOnEdit?: { onInsertToDOM: (_: HTMLDivElement) => void; element: JSX.Element };
  disabled?: boolean;
  id?: string;
  onAnswerChange: (_: EventTarget | null) => void;
  options?: {
    [QuestionType.NUMBER]?: { min: number; max: number; step?: number };
  };
  placeholder?: string;
  readOnly?: boolean;
  type: QuestionType;
  value?: string;
  width?: 'w-full' | 'w-48';
}) => {
  const answerTopContainer = document.createElement('div');
  answerTopContainer.classList.add('answer-container', 'w-full');

  const answerContainer = document.createElement('div');
  answerContainer.classList.add('flex', width);

  let answerInput: HTMLInputElement | null;
  if (type === QuestionType.TEXTAREA) {
    answerInput = null;
    const textAreaRoot = createRoot(answerContainer);
    textAreaRoot.render(
      <AutoSizeTextArea
        id={id}
        className="answer-input"
        disabled={disabled}
        hasPrefix={!readOnly}
        initialValue={value ?? ''}
        onChange={(v) => onAnswerChange(v)}
        placeholder={placeholder}
      >
        {!readOnly && addOnEdit?.element}
      </AutoSizeTextArea>
    );
  } else {
    const tempAnswerInput: HTMLInputElement = document.createElement('input');
    if (type === QuestionType.CURRENCY) {
      tempAnswerInput.type = 'number';
      if (readOnly) {
        tempAnswerInput.classList.add('rounded-l-none');

        const prefix = document.createElement('span');
        prefix.classList.add(
          'inline-flex',
          'items-center',
          'px-3',
          'text-sm',
          'text-gray-900',
          'bg-gray-200',
          'border',
          'rounded-s-md',
          'border-gray-300'
        );
        prefix.textContent = '$';
        answerContainer.appendChild(prefix);
      }
    } else if (type === QuestionType.DATE) {
      tempAnswerInput.type = 'date';
    } else if (type === QuestionType.EMAIL) {
      tempAnswerInput.type = 'email';
    } else if (type === QuestionType.NUMBER) {
      tempAnswerInput.type = 'number';

      const numberOptions = options?.[QuestionType.NUMBER];
      if (numberOptions) {
        tempAnswerInput.min = numberOptions.min.toString();
        tempAnswerInput.max = numberOptions.max.toString();
        if (numberOptions.step) tempAnswerInput.step = numberOptions.step.toString();
      }
    } else if (type === QuestionType.PHONE) {
      tempAnswerInput.type = 'tel';
    }

    answerInput = tempAnswerInput;
  }

  if (answerInput) {
    answerInput.classList.add('answer-input', 'w-full', ...inputClasses);

    if (id) answerInput.id = id;

    if (!readOnly && addOnEdit) {
      answerInput.classList.add('rounded-l-none');
      addOnEdit.onInsertToDOM(answerContainer);
    }

    if (answerInput instanceof HTMLInputElement) {
      (answerInput as HTMLInputElement).placeholder = placeholder;
      (answerInput as HTMLInputElement).value = value ?? '';

      if (disabled) (answerInput as HTMLInputElement).disabled = disabled;
      if (readOnly) answerInput = onReadonlyInputKeydown(answerInput as HTMLInputElement);

      answerInput.oninput = (e) => onAnswerChange(e.target);
      answerInput.onchange = (e) => onAnswerChange(e.target);

      answerContainer.appendChild(answerInput);
    }
  }

  answerTopContainer.appendChild(answerContainer);

  return answerTopContainer;
};
