import { gql } from '@apollo/client';
import { COMPANY_OVERVIEW_FRAGMENT } from '../companies/company.queries';
import { LOCATION_FRAGMENT } from '../locations/location.queries';
import { DEPARTMENT_FRAGMENT } from '../departments/department.queries';
import { USER_OVERVIEW_FRAGMENT } from '../users/user.queries';

const INVITE_OVERVIEW_FRAGMENT = gql`
  fragment InviteOverview on Invite {
    _id
    department {
      ...DepartmentDetails
    }
    email
    expiresAt
    isExpired
    location {
      ...LocationDetails
    }
    roles
    sentAt
    status
  }

  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

const INVITE_FRAGMENT = gql`
  fragment InviteDetails on Invite {
    _id
    code
    company {
      ...CompanyOverview
    }
    department {
      ...DepartmentDetails
    }
    email
    expiresAt
    isExpired
    location {
      ...LocationDetails
    }
    roles
    sentAt
    sentBy {
      ...UserOverview
    }
    status
  }

  ${COMPANY_OVERVIEW_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${USER_OVERVIEW_FRAGMENT}
`;

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($code: String!) {
    acceptInvite(code: $code) {
      ...InviteDetails
    }
  }

  ${INVITE_FRAGMENT}
`;

export const GET_INVITE = gql`
  query GetInvite($code: String!, $status: STATUS) {
    invite(code: $code, status: $status) {
      ...InviteDetails
    }
  }

  ${INVITE_FRAGMENT}
`;

export const GET_COMPANY_INVITES = gql`
  query GetCompanyInvites($status: STATUS) {
    invites(status: $status) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const RESEND_INVITE = gql`
  mutation ResendInvite($_id: ID!) {
    resendInvite(_id: $_id) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const REMOVE_INVITE = gql`
  mutation RemoveInvite($_id: ID!) {
    removeInvite(_id: $_id) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const SEND_INVITES = gql`
  mutation SendInvites($department: [ID!]!, $emails: [String!]!, $location: [ID!]!, $roles: [ROLE!]!) {
    sendInvites(department: $department, emails: $emails, location: $location, roles: $roles) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;

export const UPDATE_INVITES = gql`
  mutation UpdateInvites($department: [ID!], $ids: [ID!]!, $location: [ID!], $roles: [ROLE!]) {
    updateInvites(department: $department, ids: $ids, location: $location, roles: $roles) {
      ...InviteOverview
    }
  }

  ${INVITE_OVERVIEW_FRAGMENT}
`;
