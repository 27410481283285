import React from 'react';
import { NavLink } from 'react-router-dom';
import { ACTIVE_HEADER_CLASS } from '../page-header-consts';

interface IMenuItem {
  label: string;
  to: string;
}

export const MenuItem: React.FC<IMenuItem> = ({ label, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `px-6 h-full justify-center items-center flex text-gray-50 hover:bg-gray-700 hover:text-white py-2 text-base font-medium ${
          isActive ? ACTIVE_HEADER_CLASS : ''
        }`
      }
    >
      {label}
    </NavLink>
  );
};
