import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useDeleteTemplate } from '../../../../domains/template/template.service';
import { ITemplateBaseDetails } from '../../../../../lib/types';
import { ITableMultiselectActionProps } from '../../../../_core/table/table.types';

export const useDeleteTemplateAction = ({
  getSelectedModels,
  clearSelected
}: ITableMultiselectActionProps<ITemplateBaseDetails>): IButtonDropdownOptionProps => {
  const { deleteTemplate } = useDeleteTemplate();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else {
        selected.forEach((template) =>
          deleteTemplate(template).catch((err: Error) => showError('Unable to delete template ' + template.title, err))
        );
        clearSelected();
      }
    },
    text: 'Delete'
  };
};
