import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';

export interface ITabProps {
  title: string;
  content: React.ReactNode;
}

export interface ITabsProps {
  tabs: ITabProps[];
}

export const Tabs = ({ tabs }: ITabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    if (selectedIndex >= tabs.length) setSelectedIndex(0);
  }, [selectedIndex, tabs.length]);

  return (
    <div className="w-full flex flex-1 flex-col">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex space-x-4 overflow-hidden font-sans w-full overflow-x-auto">
          {tabs.map(({ title }, i) => (
            <Tab
              key={i}
              className={({ selected }) =>
                `font-bold ${
                  selected ? 'bg-secondary text-white' : 'bg-gray-200 text-gray-600'
                } w-full max-w-60 text-center text-[15px] py-1 px-4 rounded-tl-2xl rounded-tr-2xl cursor-pointer`
              }
            >
              {title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="flex flex-1 flex-col min-w-0">
          {tabs.map(({ content, title }) => (
            <Tab.Panel key={`tab-content-${title}`} className="flex flex-1 flex-col">
              {content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
