import { useFormFieldMeta } from '../form-field-meta';
import { useCallback, useRef, useState } from 'react';
import { IFormInputProps } from './form-input.component';
import { Tag } from '../../tag.component';
import { useOnClickOutside } from '../../utils/click';

export interface IFormTagsInputProps {
  previewLabelPrefix?: string;
  inputType?: HTMLInputElement['type'];
}

interface IFormTagsInputHiddenProps {
  error?: React.ReactNode;
  inputClasses: string;
}

export const FormTagsInput = ({
  containerClass = 'w-2/3',
  error,
  inputClasses,
  inputType = 'text',
  name,
  placeholder,
  previewLabelPrefix
}: IFormTagsInputProps & IFormInputProps & IFormTagsInputHiddenProps) => {
  const {
    value: formValue,
    helpers: { setTouched, setValue: setFormValue }
  } = useFormFieldMeta<string[]>(name);

  const [currInputValue, setCurrInputValue] = useState('');
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  // On click outside of the component, mark as touched (prevents invalid touch state showing as error before value can be selected)
  const ref = useRef(null);
  const handleClickOutside = useCallback(() => {
    if (hasBeenTouched) setTouched(true);
  }, [hasBeenTouched, setTouched]);

  useOnClickOutside(ref, handleClickOutside);

  const handleEnter = useCallback(() => {
    if (currInputValue) {
      if (!formValue.includes(currInputValue)) setFormValue([...formValue, currInputValue]);
      setCurrInputValue('');
    }
  }, [currInputValue, formValue, setFormValue]);

  const handleRemove = useCallback(
    (tag: string) => {
      setFormValue(formValue.filter((t) => t !== tag));
    },
    [formValue, setFormValue]
  );

  return (
    <div className={containerClass} ref={ref} onClick={() => setHasBeenTouched(true)}>
      <div className="relative">
        <input
          className={inputClasses}
          onChange={(e) => setCurrInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleEnter();
              e.preventDefault();
            }
          }}
          placeholder={placeholder}
          type={inputType}
          value={currInputValue}
        />
        <div className={currInputValue ? 'block' : 'hidden'}>
          <div className="absolute z-30 left-0 mt-2 w-full">
            {/* NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations */}
            <div className="py-1 text-sm bg-white rounded shadow-lg border border-gray-300">
              <a
                className="block py-1 px-5 cursor-pointer hover:bg-indigo-600 hover:text-white"
                onClick={() => handleEnter()}
              >
                {!!previewLabelPrefix && previewLabelPrefix} "<span className="font-semibold">{currInputValue}</span>"
              </a>
            </div>
          </div>
        </div>
        {error}
        <div>
          {formValue.map((tag, i) => (
            <Tag key={'tag-' + tag + i} onRemove={() => handleRemove(tag)} tag={tag} />
          ))}
        </div>
      </div>
    </div>
  );
};
