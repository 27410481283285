import { stickyTableCellClass } from './table-consts';

interface ITableCheckboxProps {
  checked?: boolean;
  header?: boolean;
  onChange?: (_: boolean) => void;
}

export const TableCheckbox = ({ checked, header, onChange }: ITableCheckboxProps) => {
  const content = (
    <div className="flex items-center">
      <input
        id="checkbox-table-search-1"
        type="checkbox"
        className={`w-4 h-4 text-blue-600 bg-gray-100 rounded focus:ring-blue-500 focus:ring-2 ${
          header ? 'border-gray-500' : 'border-gray-300'
        }`}
        checked={!!checked}
        onChange={onChange ? (e) => onChange(e.target.checked) : undefined}
      />
      <label htmlFor="checkbox-table-search-1" className="sr-only hidden">
        checkbox
      </label>
    </div>
  );

  if (header) return <th className={`w-8 px-2 py-1 ${stickyTableCellClass}`}>{content}</th>;
  return <td className="w-8 px-2 py-1">{content}</td>;
};
