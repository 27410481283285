import { IInvite, IMemberOverview } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { SendInvitesContainer } from '../../../domains/invite/send/send-invites-form.container';
import { ITableFilterConfig, TableWithActions } from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';

interface IStaffTableProps extends PropsWithChildren, IAdminProps {
  inviteStaff: boolean;
  onSelect: (_index: number, _selected?: boolean) => void;
  onSelectAll: (_selected: boolean) => void;
  selected?: boolean[];
  setInviteStaff: React.Dispatch<React.SetStateAction<boolean>>;
  staff: IStaffTableRowValues[];
  filterConfig?: ITableFilterConfig<IInvite | IMemberOverview>;
}

export interface IStaffTableRowValues extends ITableRowValues {
  invite?: IInvite;
  member?: IMemberOverview;
}

export const StaffTable: React.FC<IStaffTableProps> = ({ children, inviteStaff, setInviteStaff, staff, ...rest }) => {
  const headers = ['Name', 'Email', 'Roles', 'Status', 'Departments', 'Locations', ''];

  return (
    <>
      <TableWithActions
        bordered
        selectable
        loading={!staff}
        headers={headers}
        values={staff}
        onRowClick={rest.onSelect}
        {...rest}
      >
        {children}
      </TableWithActions>
      <RIDialog open={inviteStaff} setOpen={setInviteStaff}>
        <SendInvitesContainer close={() => setInviteStaff(false)} />
      </RIDialog>
    </>
  );
};
