import { useCallback, useMemo } from 'react';
import download from 'downloadjs';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { showError } from '../../../lib/utils';
import { Button } from '../../_core/button/button.component';
import { useDownloadAllAssets } from '../assets/asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadData, UploadItemData } from '../../_editor/upload/upload.types';
import { IRequest, IRequestBlock, IRequestTokenParams } from '../../../lib/types';
import { getDownloadFileName } from '../assets/download-utils';
import { EditorSaveResult } from '../../_editor/_core/types';

interface IDownloadRequestAssetsButton extends IRequestTokenParams {
  forceLabelsOnDocProvide?: boolean; // Force use labels on doc provide downloads
  hasFiles: boolean;
  onSave: () => Promise<EditorSaveResult>;
}

const DownloadRequestAssetsButton = ({
  forceLabelsOnDocProvide,
  hasFiles,
  onSave,
  requestToken
}: IDownloadRequestAssetsButton) => {
  const { downloadAssets } = useDownloadAllAssets(requestToken);

  const findFilesToDownload = useCallback(
    (blocks?: IRequestBlock[]) => {
      const foundFiles: (UploadItemData & { forceLabel?: boolean })[] = [];

      const updateFoundFiles = (data: object, type?: string | null) => {
        Object.entries(data).forEach(([key, value]) => {
          if (!value) return;

          if (key === 'uploads') {
            const foundUploads = (data as UploadData).uploads;
            foundFiles.push(
              ...(forceLabelsOnDocProvide && type === 'docProvide'
                ? foundUploads.map((u) => ({ ...u, forceLabel: true }))
                : foundUploads)
            );
          } else if (typeof value === 'object') {
            updateFoundFiles(value, 'type' in value ? value['type'] : type);
          }
        });
      };
      blocks?.forEach(({ data }) => updateFoundFiles(data));

      return foundFiles;
    },
    [forceLabelsOnDocProvide]
  );

  const handleDownload = useCallback(
    ({ useOriginalFileName }: { useOriginalFileName?: boolean }) => {
      onSave()
        .then(({ data: savedData }) => {
          if (savedData) {
            const items = findFilesToDownload(savedData.blocks as IRequestBlock[]);
            downloadAssets({ items })
              .then((results) =>
                results.forEach((file, i) => {
                  if (file)
                    return download(
                      file.data,
                      getDownloadFileName({
                        itemData: items[i],
                        useOriginalFileName: items[i].forceLabel ? false : useOriginalFileName
                      })
                    );
                })
              )
              .catch((err) => showError('Failed to download files', err));
          }
        })
        .catch((err) => showError('Failed to download files', err));
    },
    [downloadAssets, findFilesToDownload, onSave]
  );

  return (
    <Button
      hideEndMargin
      fit="right"
      icon={<ArrowDownTrayIcon height={24} width={24} />}
      variant="secondary"
      dropdown={{
        options: [
          { text: 'With original filenames', onClick: () => handleDownload({ useOriginalFileName: true }) },
          { text: 'With labels as file names', onClick: () => handleDownload({}) }
        ],
        hideIcon: true,
        ignoreRelativeContainer: true,
        maxWidth: 'max-w-52'
      }}
      disabled={!hasFiles}
    />
  );
};

interface IDownloadRequestAssetsButtonContainer extends IRequestTokenParams {
  forceLabelsOnDocProvide?: boolean;
  onSave: () => Promise<EditorSaveResult>;
  request: IRequest;
}

export const DownloadRequestAssetsButtonContainer = ({
  onSave,
  request,
  ...rest
}: IDownloadRequestAssetsButtonContainer) => {
  const hasFiles = useMemo(() => {
    const findFileToDownload = (data?: object | null): boolean => {
      return (
        !!data &&
        Object.entries(data).some(([key, value]) => {
          if (key === 'uploads') {
            return !!(data as UploadData).uploads.length;
          } else if (typeof value === 'object') {
            return findFileToDownload(value);
          }

          return false;
        })
      );
    };
    return !!request.blocks?.some(({ data }) => findFileToDownload(data));
  }, [request]);

  return (
    <EditorApolloProvider>
      <DownloadRequestAssetsButton hasFiles={hasFiles} onSave={onSave} {...rest} />
    </EditorApolloProvider>
  );
};
