import { IEntity } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import {
  ITableFilterConfig,
  ITableModeConfig,
  ITableSearchConfig,
  TableWithActions
} from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IEntityTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  bordered?: boolean;
  entities: IEntityTableRowValues[];
  filterConfig?: ITableFilterConfig<IEntity>;
  modeConfig?: ITableModeConfig;
  searchConfig?: ITableSearchConfig;
}

export interface IEntityTableRowValues extends ITableRowValues {
  entity: IEntity;
}

export const EntityTable: React.FC<IEntityTableProps> = ({
  bordered,
  children,
  isPersonalCompany,
  entities,
  ...rest
}) => {
  const headers = ['Name', 'Status', 'Main Contact'];
  if (!isPersonalCompany) headers.push('Departments', 'Locations');
  headers.push('');

  return (
    <>
      <TableWithActions bordered={bordered} loading={!entities} headers={headers} values={entities} {...rest}>
        {children}
      </TableWithActions>
    </>
  );
};
