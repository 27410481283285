import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { EditRequestTypesNestedContainer } from './edit-request-types-form-nested.container';

export const EditRequestTypesContainer = () => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Edit Request Types"
    >
      <EditRequestTypesNestedContainer
        onError={(e) => setSubmitError(e ?? null)}
        onSuccess={(s) => setSuccess(s ?? null)}
      />
    </FormContainer>
  );
};
