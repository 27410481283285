import { useResendInvite } from '../../../../domains/invite/invite.service';
import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { IStaffTableMultiselectActionProps } from './staff-table-multiselect-action-button.types';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { showError } from '../../../../../lib/utils';

export const useResendInviteAction = ({
  getSelectedStaffModels,
  clearSelected
}: IStaffTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { resendInvite } = useResendInvite();

  const resendInvites = useCallback(async () => {
    const models = getSelectedStaffModels();
    if (!models?.selectedInvites.length) toast.error('No invites selected');
    else {
      try {
        const result = await Promise.all(models.selectedInvites.map(resendInvite));
        if (result.some((r) => !r.data?.resendInvite.sentAt)) {
          toast.error('Unable to deliver some invites');
        } else toast.success(`Resent ${models.selectedInvites.length} invites`);

        clearSelected();
      } catch (err) {
        showError('Unable to resend invites', err as Error);
      }
    }
  }, [getSelectedStaffModels, resendInvite, clearSelected]);

  return { onClick: resendInvites, text: 'Resend Invites' };
};
