import { useMemo } from 'react';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';

import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendInvitesProps } from '../invite.types';
import { IDepartment, IInvite, ILocation, ROLE, ROLE_LABELS } from '../../../../lib/types';
import { BASIC_ROLE_OPTIONS } from '../../../../_configuration';
import { isValidEmail } from '../../../../utils/validation.helpers';
import { IPersonalCompanyProps } from '../../../_core/core.types';

interface ISendInvitesFormProps extends IRIFormProps<ISendInvitesProps>, IPersonalCompanyProps {
  departments: IDepartment[];
  isClientEntityInvite: boolean;
  isClientInvite: boolean;
  locations: ILocation[];
  results?: null | IInvite[];
}

export const SendInvitesForm = ({
  departments,
  isClientEntityInvite,
  isClientInvite,
  isPersonalCompany,
  locations,
  onBack,
  onSkip,
  onSubmit
}: ISendInvitesFormProps) => {
  const validateForm = (values: ISendInvitesProps) => {
    const errors: FormikErrors<ISendInvitesProps> = {};

    if (!values.emails.length) {
      errors.emails = 'At least one value required';
    }

    const invalidEmails = values.emails.filter((email) => !isValidEmail(email.trim()));
    if (invalidEmails.length) {
      errors.emails = 'Invalid email address: ' + invalidEmails.join(', ');
    }

    if (!isClientInvite && !values.roles?.length) {
      errors.roles = 'At least one value required';
    }

    return errors;
  };

  const options = useMemo(
    () => ({
      departments: departments.map((d) => ({ label: d.name, value: d._id })),
      locations: locations.map((l) => ({ label: l.name, value: l._id }))
    }),
    [departments, locations]
  );

  return (
    <Formik
      initialValues={
        {
          emails: [],
          department: [],
          location: [],
          roles: isClientInvite ? undefined : [{ label: ROLE_LABELS[ROLE.COMPANY_STAFF], value: ROLE.COMPANY_STAFF }]
        } as ISendInvitesProps
      }
      onSubmit={(values) => onSubmit({ ...values, emails: values.emails.filter((email) => email.trim()) })}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendInvitesProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto">
          {!isClientInvite && (
            <>
              <p className="font-light text-sm mb-4">Invite your team to collaborate with you on the platform.</p>
              <p className="font-light text-sm mb-4">
                You can send invites now, or come back and invite them later at any time.
              </p>
            </>
          )}
          {isClientInvite && (
            <>
              {isClientEntityInvite && (
                <p className="font-light text-sm mb-4">Add some contacts for your contact company below.</p>
              )}
              {!isClientEntityInvite && (
                <p className="font-light text-sm mb-4">Add some contacts for your organization below.</p>
              )}
              <p className="font-light text-sm mb-4">You can also access the contacts list in your settings.</p>
            </>
          )}

          <div
            className={`flex flex-col${
              options.departments.length > 0 || options.locations.length > 0 ? ' border-b border-dashed mb-8 pb-4' : ''
            }`}
          >
            <div className="mb-4">
              <FormInputWithLabel
                id="emails"
                name="emails"
                label="Emails"
                placeholder="Add email"
                value={props.values.emails}
                type="tags"
                tagsProps={{ previewLabelPrefix: 'Add email', inputType: 'email' }}
              />
            </div>
            {!isClientInvite && (
              <div className="mb-4">
                <FormInputWithLabel
                  id="roles"
                  name="roles"
                  label="Roles"
                  value={props.values.roles}
                  type="multiselect"
                  placeholder="Select Roles"
                  multiSelectProps={{ options: BASIC_ROLE_OPTIONS, singleSelect: true, skipSort: true }}
                />
              </div>
            )}
          </div>
          {(options.locations.length > 0 || options.departments.length > 0) && !isPersonalCompany && (
            <div className="flex flex-col mb-8">
              <p className="font-light text-sm mb-4">
                If you want to organize {isClientInvite ? 'you clients' : 'your team'} by{' '}
                {options.locations.length ? 'office locations' : ''}
                {options.locations.length || options.departments.length ? ' or ' : ''}
                {options.departments.length ? 'departments' : ''}, please fill out the fields below.
              </p>
              <div className="mb-4">
                <FormInputWithLabel
                  id="location"
                  name="location"
                  label="Locations"
                  value={props.values.roles}
                  type="multiselect"
                  placeholder="Select Locations"
                  multiSelectProps={{ options: options.locations }}
                />
              </div>
              <div className="mb-4">
                <FormInputWithLabel
                  id="department"
                  name="department"
                  label="Departments"
                  value={props.values.roles}
                  type="multiselect"
                  placeholder="Select Departments"
                  multiSelectProps={{ options: options.departments }}
                />
              </div>
            </div>
          )}
          <div>
            <div className="flex-row justify-between mt-4">
              <FormButton loading={props.isSubmitting} disabled={!props.values.emails?.length} text="Send" />
              {(!!onBack || !!onSkip) && (
                <div className="flex justify-between mt-4">
                  {!!onBack && (
                    <div className="w-24 basis-auto">
                      <FormButton
                        icon={<ArrowLongLeftIcon className="w-6 h-6 mr-2" />}
                        onPress={onBack}
                        text="Back"
                        type="button"
                        variant="text"
                      />
                    </div>
                  )}
                  {!!onSkip && (
                    <div className="w-24 basis-auto">
                      <FormButton onPress={onSkip} text="Skip" type="button" variant="text" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
