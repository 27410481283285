import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { ISendClientInvitesProps } from '../invite.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IClientOverview } from '../../../../lib/types';
import { useState } from 'react';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { CreateClientInviteContainer } from '../create-client/create-client-invite-form.container';

interface ISendClientInvitesFormProps extends IRIFormProps<ISendClientInvitesProps> {
  clients: IClientOverview[];
  hasEntity: boolean; // Default entity, if present then don't allow changing of selected entity
}

export const SendClientInvitesForm = ({
  clients,
  hasEntity,
  onBack,
  onSkip,
  onSubmit
}: ISendClientInvitesFormProps) => {
  const [newContactData, setNewContactData] = useState('');

  const validateForm = (values: ISendClientInvitesProps) => {
    const errors: FormikErrors<ISendClientInvitesProps> = {};
    if (!values.invites.length) errors.invites = 'At least one invite is required';
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ invites: [] } as ISendClientInvitesProps}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
      >
        {({ values, isSubmitting, setValues }: FormikProps<ISendClientInvitesProps>) => (
          <>
            <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
              {!hasEntity && (
                <FormInfo
                  title={'Add Contacts:'}
                  messages={[
                    'Add some contacts for your organization below.',
                    'You can also access the contacts list in your settings.'
                  ]}
                />
              )}
              <FormInputWithLabel
                id="invites"
                name="invites"
                label="Contacts"
                value={values.invites}
                type="multiselect"
                placeholder="Contacts"
                multiSelectProps={{
                  options: clients.map((c) => ({
                    value: c._id,
                    label: c.name ?? c.user.name,
                    secondaryLabel: c.user.email
                  })),
                  nullable: true,
                  canAdd: true,
                  onAdd: setNewContactData
                }}
              />
              <FormActionsRow>
                {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
                {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
                <FormButton size={!onBack && !onSkip ? 'medium' : undefined} loading={isSubmitting} text="Submit" />
              </FormActionsRow>
            </Form>
            <RIDialog open={!!newContactData} setOpen={(o) => setNewContactData((d) => (o ? d : ''))} locked>
              <CreateClientInviteContainer
                email={newContactData}
                close={() => setNewContactData('')}
                afterSuccess={(newClient) => {
                  setValues({
                    ...values,
                    invites: [
                      ...values.invites,
                      {
                        value: newClient._id,
                        label: newClient.name ?? newClient.user.name,
                        secondaryLabel: newClient.user.email
                      }
                    ]
                  });
                  setNewContactData('');
                }}
              />
            </RIDialog>
          </>
        )}
      </Formik>
    </>
  );
};
