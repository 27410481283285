import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';

interface IUpdateDepartmentFormValues {
  name: string;
}

interface IUpdateDepartmentFormProps extends IRIFormProps<IUpdateDepartmentFormValues> {
  name: string;
}

export const UpdateDepartmentForm = ({ name, onSubmit }: IUpdateDepartmentFormProps) => {
  const validateForm = (values: IUpdateDepartmentFormValues) => {
    const errors: FormikErrors<IUpdateDepartmentFormValues> = {};

    if (!values.name.trim()) {
      errors.name = 'Required';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{ name } as IUpdateDepartmentFormValues}
      onSubmit={(values) => onSubmit({ name: values.name.trim() })}
      validate={validateForm}
    >
      {(props: FormikProps<IUpdateDepartmentFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel
            id="name"
            name="name"
            label="Name"
            placeholder="Edit department name"
            value={props.values.name}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
