import { PropsWithChildren } from 'react';

interface ITooltipProps extends PropsWithChildren {
  className?: string;
  direction?: 'bottom' | 'left' | 'top';
  distance?: 'lg' | 'md' | 'sm';
  singleLine?: boolean;
  text?: string;
}

// NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations
export const Tooltip = ({
  children,
  className,
  direction = 'left',
  distance = 'md',
  singleLine,
  text
}: ITooltipProps) => {
  if (!text) return children;
  const isMedium = distance === 'md';
  const isLarge = distance === 'lg';
  const additionalClasses = [
    ['left'].includes(direction) ? (isLarge ? 'right-14' : isMedium ? 'right-10' : 'right-6') : null,
    ['bottom'].includes(direction) ? (isLarge ? 'top-14' : isMedium ? 'top-10' : 'top-6') : null,
    ['top'].includes(direction) ? (isLarge ? 'bottom-14' : isMedium ? 'bottom-10' : 'bottom-6') : null,
    singleLine ? 'whitespace-nowrap' : '',
    className
  ]
    .filter((c) => c)
    .join(' ');

  return (
    <span className="group relative flex items-center text-xs leading-6">
      {children}
      <span
        className={`absolute z-50 scale-0 rounded bg-gray-800 p-2 transition-all text-xs text-white group-hover:scale-100 ${additionalClasses}`}
      >
        {text}
      </span>
    </span>
  );
};
