import { gql } from '@apollo/client';

export const DEPARTMENT_FRAGMENT = gql`
  fragment DepartmentDetails on Department {
    _id
    name
    deletedAt
  }
`;

export const CREATE_DEPARTMENTS = gql`
  mutation CreateDepartments($names: [String!]!) {
    createDepartments(names: $names) {
      ...DepartmentDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
`;

export const GET_COMPANY_DEPARTMENTS = gql`
  query GetCompanyDepartments($includeArchived: Boolean) {
    departments(includeArchived: $includeArchived) {
      ...DepartmentDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($_id: ID!) {
    deleteDepartment(_id: $_id) {
      ...DepartmentDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
`;

export const RESTORE_DEPARTMENT = gql`
  mutation RestoreDepartment($_id: ID!) {
    restoreDepartment(_id: $_id) {
      ...DepartmentDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
`;

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment($_id: ID!, $name: String!) {
    updateDepartment(_id: $_id, name: $name) {
      ...DepartmentDetails
    }
  }

  ${DEPARTMENT_FRAGMENT}
`;
