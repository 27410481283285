import { TableCell } from './table-cell.component';
import { TableCheckbox } from './table-checkbox.component';
import { ITableRowProps } from './table.types';

export const TableHeader = ({ onSelect, rowClasses, selected, selectable, values }: ITableRowProps) => {
  return (
    <thead className="text-xs text-gray-900 uppercase bg-gray-300">
      <tr>
        {selectable && <TableCheckbox header checked={selected} onChange={onSelect} />}
        {values.map(({ children, key }, i) => (
          <TableCell
            header
            key={key ?? `table-header-${i}-` + children?.toLocaleString()}
            rowClass={rowClasses?.length && rowClasses.length > i ? rowClasses[i] : undefined}
          >
            {children}
          </TableCell>
        ))}
      </tr>
    </thead>
  );
};
