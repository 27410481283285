import { NavigateFunction } from 'react-router-dom';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { RequestTableContainer } from './request-table/request-table.container';

interface IDashboardPage extends IAdminProps, IPersonalCompanyProps {
  navigate: NavigateFunction;
}

export const DashboardPage: React.FC<IDashboardPage> = (props) => {
  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <RequestTableContainer {...props} />
    </div>
  );
};
