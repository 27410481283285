import React, { useCallback, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { useFormFieldMeta } from '../form-field-meta';
import { IFormInputProps } from './form-input.component';
import { useOnClickOutside } from '../../utils/click';
import { getSelectOptionsClasses } from './form-utils';

export interface IFormSelectOption {
  label?: string;
  value: string;
}

export interface IFormSelectInputProps {
  options?: IFormSelectOption[];
}

interface IFormSelectInputHiddenProps {
  error?: React.ReactNode;
  inputClasses: string;
}

export const FormSelectInput = ({
  containerClass = 'w-2/3',
  error,
  inputClasses,
  name,
  options = [],
  placeholder = 'Select one option'
}: IFormSelectInputProps & IFormInputProps & IFormSelectInputHiddenProps) => {
  const {
    value: formValue,
    helpers: { setTouched, setValue: setFormValue }
  } = useFormFieldMeta<string>(name);

  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  // On click outside of the component, close the dropdown
  const ref = useRef(null);
  const handleClickOutside = useCallback(() => {
    if (hasBeenTouched) setTouched(true);
  }, [hasBeenTouched, setTouched]);

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={containerClass} onClick={() => setHasBeenTouched(true)}>
      <div className="relative" ref={ref}>
        <Listbox value={formValue} onChange={setFormValue}>
          <Listbox.Button
            className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${inputClasses}`}
          >
            {formValue ?? placeholder}
          </Listbox.Button>
          <Listbox.Options
            className={`shadow top-100 bg-white z-30 w-full lef-0 rounded max-h-24 overflow-y-auto absolute mt-2`}
          >
            {options.map((o) => (
              <Listbox.Option key={o.value} value={o.value} className={getSelectOptionsClasses}>
                {o.label ?? o.value}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
        {error}
      </div>
    </div>
  );
};
