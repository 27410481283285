import { GET_MEMBERS } from './member.queries';
import { IMembersResults } from './member.types';
import { IMemberOverview } from '../../../lib/types';
import { IApolloCacheParams } from '../../../lib/cache.types';
import { mergeCacheLists } from '../../../lib/utils';

export const updateMemberCache = ({ cache, members }: IApolloCacheParams & { members: IMemberOverview[] }) => {
  const currMembers = cache.readQuery<IMembersResults>({ query: GET_MEMBERS })?.members;
  if (currMembers) cache.writeQuery({ query: GET_MEMBERS, data: { members: mergeCacheLists(currMembers, members) } });
};
