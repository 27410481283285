import { PropsWithChildren } from 'react';

interface PropsWithChildrenAndClass extends PropsWithChildren {
  className?: string;
}

// Labels
export const Label = ({ children }: PropsWithChildren) => (
  <span className="font-bold text-xl tracking-tight uppercase flex-1 max-w-xs mr-4">{children}</span>
);

export const LabelValue = ({ children, placeholder }: PropsWithChildren & React.HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={`font-light text-xl tracking-tight flex-1 text-left ${children ? 'text-black' : 'text-gray-400'}`}
    placeholder={placeholder}
  >
    {children || placeholder}
  </span>
);

// Titles
export const PageTitle = ({ children }: PropsWithChildren) => (
  <span className="ml-2 text-white md:text-3xl sm:text-xl">{children}</span>
);

export const SectionTitle = ({ children }: PropsWithChildren) => (
  <span className="text-black-800 text-xl border-b border-gray-200 uppercase text-center w-full">{children}</span>
);

// Basic Texts

// Meant for use as a text field in combo of other similar items
export const LowlightText = ({ className, children }: PropsWithChildrenAndClass) => (
  <p
    className={`text-xs text-gray-500 whitespace-nowrap overflow-hidden text-ellipsis z-10 ${className ?? ''}`}
    title={children?.toString()}
  >
    {children}
  </p>
);

export const LowlightTextWithSubtitle = ({
  centered,
  className,
  children,
  containerClass,
  subtitle
}: PropsWithChildrenAndClass & { centered?: boolean; containerClass?: string; subtitle?: string }) => (
  <div className={`flex flex-col ${centered && 'items-center'} ${containerClass ?? ''}`}>
    <LowlightText className={className}>{children}</LowlightText>
    {!!subtitle && <LowlightText className="!text-2xs">{subtitle}</LowlightText>}
  </div>
);

// Meant for use to draw attention to basic text
export const HighlightText = ({ className, children }: PropsWithChildrenAndClass) => (
  <span className={`text-sm font-medium text-black ${className ?? ''}`}>{children}</span>
);

export const ScreenReaderText = ({ children }: PropsWithChildren) => <span className="sr-only">{children}</span>;
