import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from '../../_routes';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useLogout } from '../domains/auth/auth.service';

interface IAuthenticatedLayoutWrapper {
  redirectMessage?: string;
  redirectMessageType?: 'error' | 'info';
  redirectPath?: ROUTE_PATHS;
}

export const AuthenticatedLayoutWrapper = ({
  redirectMessage = 'You must login to continue.',
  redirectMessageType = 'error',
  redirectPath = ROUTE_PATHS.LANDING
}: IAuthenticatedLayoutWrapper) => {
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const { handleLogout } = useLogout();

  useEffect(() => {
    if (!isAuthenticated) {
      handleLogout({
        afterExecution: () => {
          if (redirectMessageType === 'error') toast.error(redirectMessage, { duration: 5000, id: 'unauthenticated' });
          else toast(redirectMessage, { duration: 5000, id: 'unauthenticated' });
        }
      });
    }
  }, [handleLogout, isAuthenticated, redirectMessage, redirectMessageType]);

  if (!isAuthenticated) return <Navigate to={redirectPath} state={{ params }} />;
  return <Outlet />;
};
