import { PropsWithChildren } from 'react';

interface IDetailsBoxProps extends PropsWithChildren {
  bordered?: boolean;
  endAligned?: boolean;
  noPaddingX?: boolean;
  rounded?: boolean;
  stacked?: boolean;
  straightTopLeft?: boolean;
  stretch?: boolean;
  topBorder?: boolean;
}

export const DetailBox: React.FC<IDetailsBoxProps> = ({
  bordered,
  children,
  endAligned,
  noPaddingX,
  rounded,
  stacked,
  straightTopLeft,
  stretch,
  topBorder
}) => (
  <div
    className={`flex flex-wrap gap-2 py-4 w-full border-gray-400 ${noPaddingX ? '' : 'px-4'} ${
      endAligned ? 'justify-end' : ''
    } ${stretch ? 'flex-1' : ''} ${bordered ? 'border' : topBorder ? 'border-t' : ''}  ${rounded ? 'rounded-md' : ''} ${
      straightTopLeft ? 'rounded-tl-none' : ''
    } ${stacked ? 'flex-col' : ''}`}
  >
    {children}
  </div>
);
