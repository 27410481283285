import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { LocalTitleBlockTool } from '../_core/local-title-block/local-title-block.component';
import { RadioOptionsInput } from './radio-options-input';
import { createRoot } from 'react-dom/client';
import { ILabelBlockData } from '../_core/label-block';
import { ICreateTemplateBlockConfig } from '../_core/create-template-block';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { radioBlockExport, radioBlockImport } from './radio.utils';
import { BlockTitles, BlockType, inputClassesLineHeightStr } from '../_core/editor.const';
import _ from 'lodash';

export interface RadioData extends ILabelBlockData {
  options: string[];
  value: string | null;
}

export class Radio extends LocalTitleBlockTool<RadioData, ICreateTemplateBlockConfig> {
  private tagUniqueClass: string;
  private optionInputId: string;

  constructor(props: BlockToolConstructorOptions<RadioData>) {
    super({
      ...props,
      data: {
        ...props.data,
        value: 'value' in props.data && props.data.value ? _.unescape(props.data.value) : null,
        options: props.data.options?.map((o) => _.unescape(o))
      }
    });

    this.tagUniqueClass = 'tag-' + this.uid;
    this.optionInputId = 'option-input-' + this.uid;
  }

  static get sanitize() {
    // disallow HTML
    return { ...super.sanitize, options: false, value: false };
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.Radio],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m3.75 7.5 16.5-4.125M12 6.75c-2.708 0-5.363.224-7.948.655C2.999 7.58 2.25 8.507 2.25 9.574v9.176A2.25 2.25 0 0 0 4.5 21h15a2.25 2.25 0 0 0 2.25-2.25V9.574c0-1.067-.75-1.994-1.802-2.169A48.329 48.329 0 0 0 12 6.75Zm-1.683 6.443-.005.005-.006-.005.006-.005.005.005Zm-.005 2.127-.005-.006.005-.005.005.005-.005.005Zm-2.116-.006-.005.006-.006-.006.005-.005.006.005Zm-.005-2.116-.006-.005.006-.005.005.005-.005.005ZM9.255 10.5v.008h-.008V10.5h.008Zm3.249 1.88-.007.004-.003-.007.006-.003.004.006Zm-1.38 5.126-.003-.006.006-.004.004.007-.006.003Zm.007-6.501-.003.006-.007-.003.004-.007.006.004Zm1.37 5.129-.007-.004.004-.006.006.003-.004.007Zm.504-1.877h-.008v-.007h.008v.007ZM9.255 18v.008h-.008V18h.008Zm-3.246-1.87-.007.004L6 16.127l.006-.003.004.006Zm1.366-5.119-.004-.006.006-.004.004.007-.006.003ZM7.38 17.5l-.003.006-.007-.003.004-.007.006.004Zm-1.376-5.116L6 12.38l.003-.007.007.004-.004.007Zm-.5 1.873h-.008v-.007h.008v.007ZM17.25 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm0 4.5a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" /></svg>'
    };
  }

  render() {
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('flex', 'gap-4', 'overflow-x-auto', 'w-full');

    const completedCheckbox = this.renderCompletedCheckbox();
    if (completedCheckbox) this.wrapper.appendChild(completedCheckbox);

    const radioInput = this.renderRadioInput({});

    this.renderWithLocalTitle();
    if (radioInput) this.wrapper.appendChild(radioInput);

    // Add required UX components, if element is required
    const indicator = this.renderRequiredIndicator();
    if (indicator) this.wrapper.appendChild(indicator);

    return renderWithBlockFocusWrapper(this.wrapper);
  }

  renderRadioInput({
    afterChange,
    canEditOptions = true
  }: {
    afterChange?: (_: string | null) => void;
    canEditOptions?: boolean;
    targetWrapper?: HTMLDivElement;
  }) {
    // Render question input, or label if in read-only mode
    let tagElement: HTMLElement;
    if (this.api.readOnly.isEnabled) {
      const radioContainer = document.createElement('div');
      radioContainer.classList.add('flex', 'gap-4', 'items-center');

      this.data.options.forEach((escapedOption) => {
        const option = _.unescape(escapedOption);
        const optionContainer = document.createElement('div');
        optionContainer.classList.add('inline-flex', 'items-center');

        const input = document.createElement('input');
        input.id = this.tagUniqueClass + '-' + option;
        input.name = this.tagUniqueClass;
        input.type = 'radio';
        input.classList.add('cursor-pointer', 'disabled:text-gray-400', 'disabled:cursor-default');
        input.onclick = (e) => this.onAnswerChange(e, afterChange);
        input.value = option;
        input.checked = this.data.value === option;
        input.disabled = this.config.disabled;
        radioContainer.appendChild(input);

        const label = document.createElement('label');
        label.textContent = option;
        label.classList.add('cursor-pointer', ...inputClassesLineHeightStr.split(' '));
        label.htmlFor = input.id;
        radioContainer.appendChild(label);
      });

      tagElement = radioContainer;
    } else {
      // // Render selected input
      // const selected = document.createElement('div');
      // const selectedTitle = document.createElement('span');
      // selectedTitle.textContent = 'Selected: ';
      // selected.appendChild(selectedTitle);

      // const selectedValue = document.createElement('span');
      // selectedValue.textContent = this.data.value ?? 'None';
      // selectedValue.classList.add('font-bold', 'value');
      // selected.appendChild(selectedValue);
      // targetWrapper?.appendChild(selected);

      // Render radio options input
      const tagContainer = document.createElement('div');
      tagContainer.id = this.optionInputId;
      tagContainer.classList.add('w-full', 'relative');

      if (!canEditOptions) return null;

      const root = createRoot(tagContainer);
      root.render(<RadioOptionsInput tagClass={this.tagUniqueClass} initialOptions={this.data.options} />);
      tagElement = tagContainer;
    }

    return tagElement;
  }

  private onAnswerChange({ target }: Event, afterChange?: (_: string | null) => void) {
    const targetE = target as HTMLInputElement;
    const clickedValue = targetE.value.trim();
    const unsetValue = clickedValue === this.data.value;

    this.data.value = unsetValue ? null : clickedValue;
    this.data.filled = !unsetValue;

    this.toggleRequiredIndicator({ checked: this.data.filled });
    if (unsetValue) targetE.checked = false;

    afterChange?.(this.data.value);
  }

  async save(blockContent: HTMLDivElement): Promise<RadioData> {
    // Get options values if the options input tag exists (indicates we are in edit mode)
    let options = this.data.options;
    const tagInput = blockContent.querySelector('#' + this.optionInputId) as HTMLInputElement | undefined;
    if (tagInput) {
      const tags = blockContent.querySelectorAll('.' + this.tagUniqueClass) as NodeListOf<HTMLSpanElement>;
      options = Array.from(tags).reduce((acc: string[], tag) => {
        if (tag.textContent) acc.push(tag.textContent);
        return acc;
      }, []);
    }

    // Prep base block data
    const newData = await super.save(blockContent);
    return { ...newData, options };
  }

  validate({ options, ...rest }: RadioData) {
    return super.validate(rest) && !!options.length;
  }

  static get conversionConfig() {
    return { export: radioBlockExport, import: radioBlockImport };
  }
}
