import { Loader } from '../../_core/loader.component';
import { IEntity, IClientInvite, Identifiable, IEntityMember, IClient } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { Tabs } from '../../_core/tabs.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { ContactsTableNestedContainer } from '../../domains/contacts/contacts-table/contacts-table.nested.container';
import { useMemo, useState } from 'react';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { UpdateEntityContainer } from '../../domains/entities/update/update-entity-form.container';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { ProfileDetailsBox } from '../../_core/details/profile-details-box.component';
import { DetailItemProps } from '../../_core/details/details.types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../_routes';
import { ContactsPageTableMode } from '../ContactsPage/contacts-page.types';

interface IEntityProfilePageProps extends IAdminProps, IPersonalCompanyProps {
  clients?: IClient[];
  deleteEntity: (_: Identifiable) => void;
  deleting: boolean;
  entity?: IEntity;
  invites?: IClientInvite[];
  members?: IEntityMember[];
  restoreEntity: (_: Identifiable) => void;
  restoring: boolean;
}

export const EntityProfilePage: React.FC<IEntityProfilePageProps> = ({
  clients,
  deleteEntity,
  deleting,
  entity,
  invites,
  isAdmin,
  isPersonalCompany,
  members,
  restoreEntity,
  restoring
}) => {
  const navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);

  const memberClients = useMemo(
    () =>
      members?.reduce((acc: IClient[], m) => {
        if (m.deletedAt) return acc;

        const found = clients?.filter(({ deletedAt }) => !deletedAt)?.find((c) => c.user._id === m.user._id);
        if (found) acc.push({ ...found, entityMemberId: m._id, entityDeletedAt: m.deletedAt });
        return acc;
      }, []),
    [clients, members]
  );

  const detailItems = useMemo(() => {
    const items: DetailItemProps[] = [{ label: 'Company', value: entity?.name }];

    if (!isPersonalCompany)
      items.push(
        { label: 'Departments', placeholder: 'None', value: entity?.department.map(({ name }) => name).join(', ') },
        { label: 'Locations', placeholder: 'None', value: entity?.location.map(({ name }) => name).join(', ') }
      );

    items.push({ label: 'Status', value: activeStatus(!entity?.deletedAt) });

    return items;
  }, [entity, isPersonalCompany]);

  if (!entity)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  return (
    <>
      <ProfileDetailsBox
        actionItems={[
          <Button size="xl" text="Edit Company" onClick={() => setShowEdit(true)} />,
          <Button
            size="xl"
            text={`${entity.deletedAt ? 'Una' : 'A'}rchive Company`}
            onClick={() => {
              if (entity.deletedAt) restoreEntity(entity);
              else deleteEntity(entity);
            }}
            loading={entity.deletedAt ? restoring : deleting}
          />
        ]}
        detailItems={detailItems}
        title="Company Information"
        onBack={() => navigate(ROUTE_PATHS.CONTACTS + '?type=' + ContactsPageTableMode.Entity.toLowerCase())}
      />
      <div className="mt-4 flex flex-1 w-full">
        <Tabs
          tabs={[
            {
              title: 'Associated Contacts',
              content:
                !invites || !memberClients ? (
                  <Loader />
                ) : (
                  <ContactsTableNestedContainer
                    bordered
                    editingDisabled
                    isAdmin={isAdmin}
                    isPersonalCompany={isPersonalCompany}
                    clients={memberClients}
                    entity={entity}
                  />
                )
            }
          ]}
        />
      </div>
      <RIDialog open={showEdit} setOpen={setShowEdit}>
        <UpdateEntityContainer {...entity} close={() => setShowEdit(false)} />
      </RIDialog>
    </>
  );
};
