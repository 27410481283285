import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IGetInviteByCodeFormParams, InviteType } from './get-invite-by-code-form.types';

interface IGetInviteByCodeContainerProps extends IRIFormProps<IGetInviteByCodeFormParams> {
  code?: string;
}

export const GetInviteByCodeForm = ({ code = '', onSubmit }: IGetInviteByCodeContainerProps) => {
  const validateForm = (values: IGetInviteByCodeFormParams) => {
    const errors: FormikErrors<IGetInviteByCodeFormParams> = {};

    if (!values.code.trim()) errors.code = 'Required';
    if (!values.type) errors.type = 'Required';

    return errors;
  };

  return (
    <Formik
      initialValues={{ code, type: InviteType.STAFF } as IGetInviteByCodeFormParams}
      onSubmit={(values) => onSubmit({ code: values.code.trim(), type: values.type as InviteType })}
      validate={validateForm}
    >
      {(props: FormikProps<IGetInviteByCodeFormParams>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-4 mt-10">
          <FormInputWithLabel id="code" name="code" label="Invite Code" value={props.values.code} />
          {/* <FormInputWithLabel
            id="type"
            name="type"
            label="Invite Type"
            value={props.values.type}
            type="select"
            selectProps={{ options: [{ value: InviteType.STAFF, label: 'Team Member' }, { value: InviteType.CLIENT }] }}
          /> */}
          <FormButton loading={props.isSubmitting} text="Find" />
        </Form>
      )}
    </Formik>
  );
};
