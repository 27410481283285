import React, { memo, useCallback, useMemo } from 'react';
import EditorJs, { API, BlockMutationEvent, OutputData } from '@editorjs/editorjs';
import DragDrop from 'editorjs-drag-drop';
import { ICreateTemplateBlockConfig, ICreateBlockTemplateParams } from '../../_editor/_core/create-template-block';

import { FormEditorType, IEditorRefProps } from './form-editor.types';
import { useFocusedBlockTracking } from '../../_editor/_core/utils/editor.utils';

import './form-editor.css';
import '../../_editor/section/section.css';
import { getEditorTools } from './form-editor.utils';
import { Button } from '../../_core/button/button.component';
import { PlusIcon } from '@heroicons/react/20/solid';
import { usePrev } from '../../../lib/utils';
import _ from 'lodash';
import { ISectionData } from '../../_editor/section/section.types';
import { EditorSaveResult } from '../../_editor/_core/types';

interface IEditor extends IEditorRefProps {
  canRefreshData?: boolean;
  setCanRefreshData?: (_: boolean) => void;
  data: OutputData;
  onChange?: (api: API, event: BlockMutationEvent | BlockMutationEvent[]) => void;
  onCreateTemplate?: (_: ICreateBlockTemplateParams) => void;
  onReady?: () => void;
  onSave: () => Promise<EditorSaveResult>;
  openFirstSection?: boolean; // Open first uncompleted section on first load
  disabled: boolean;
  nested?: boolean;
  previewing?: boolean;
  quickCreateTitle?: string;
  readOnly: boolean;
  requestId: string;
  requestSent?: boolean;
  type?: FormEditorType;
  token?: string;
}

const Editor: React.FC<IEditor> = ({
  canRefreshData,
  setCanRefreshData,
  data,
  editorRef,
  onChange,
  onCreateTemplate,
  onReady,
  onSave,
  openFirstSection,
  editorblock,
  disabled,
  nested,
  previewing,
  quickCreateTitle,
  readOnly,
  requestId,
  requestSent,
  type,
  token
}) => {
  // TODO: Why is this re-rendering despite memoized and containing component having no state or non-callbacks? need to troubleshoot with why-did-you-render
  // console.log('Why re-render?');

  const prevData = usePrev(data);
  const prevDisabled = usePrev(disabled);
  const prevReadOnly = usePrev(readOnly);

  useFocusedBlockTracking({ editorblock, editorRef });

  const blocksWithOpenSections = useMemo(() => {
    // NOTE: Determine which section should be opened first for client view based on first incomplete section
    let markedOpen = false;

    const newBlocks = data.blocks?.map(({ data: blockData, type, ...block }, blockIndex) => {
      const updatedData = { ...blockData };
      if (type === 'section') {
        updatedData.blockIndex = blockIndex;
        updatedData.totalBlocks = data.blocks?.length ?? 0;
        updatedData.requestSent = requestSent;
      }

      if (type !== 'section' || markedOpen) return { data: updatedData, type, ...block };

      const forceOpen = !openFirstSection || !(updatedData as ISectionData).completed;
      if (openFirstSection && forceOpen) markedOpen = true;
      return { ...block, type, data: { ...updatedData, forceOpen } };
    });
    return newBlocks;
  }, [data.blocks, openFirstSection, requestSent]);

  useMemo(() => {
    // Destroy editor and re-initialize when disabled status changes, so that editor components are re-initialized with disabled config values
    if (
      !editorRef.current ||
      (prevDisabled !== undefined && prevDisabled !== disabled) ||
      (prevReadOnly !== undefined && prevReadOnly !== readOnly) ||
      (prevData !== undefined && !_.isEqual(prevData, data) && canRefreshData)
    ) {
      if (canRefreshData && setCanRefreshData) setCanRefreshData(false);
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
      } else {
        const config: ICreateTemplateBlockConfig = {
          disabled: !!disabled,
          onCreateTemplate,
          onSave,
          readOnly,
          requestId
        };

        const editor = new EditorJs({
          holder: editorblock,
          tools: getEditorTools(config, type, token),
          defaultBlock: 'paragraph',
          readOnly: readOnly || disabled,
          data: { ...data, blocks: blocksWithOpenSections },
          onChange,
          onReady: () => {
            new DragDrop(editor);
            onReady?.();
          },
          minHeight: readOnly || disabled ? 0 : 5,
          placeholder: 'Add content here!'
        });

        editorRef.current = editor;
      }
    }

    //Add a return function to handle cleanup
    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
      }
    };
  }, [
    editorRef,
    prevDisabled,
    disabled,
    prevReadOnly,
    readOnly,
    prevData,
    data,
    canRefreshData,
    setCanRefreshData,
    editorblock,
    onCreateTemplate,
    onSave,
    requestId,
    type,
    token,
    blocksWithOpenSections,
    onChange,
    onReady
  ]);

  // Quickly insert new blocks into the document
  const onQuickCreate = useCallback(() => {
    if (editorRef.current) {
      const blockCount = editorRef.current.blocks.getBlocksCount();
      const lastBlock = editorRef.current.blocks.getBlockByIndex(blockCount - 1);

      // If there are no blocks or last block is empty, then prompt user with block menu
      if (blockCount <= 1 && lastBlock?.isEmpty) {
        editorRef.current.focus(true);
        editorRef.current.toolbar.toggleToolbox(true);
      }
      // Otherwise create a new empty block matching the last one in the editor
      else
        editorRef.current.blocks.insert(
          lastBlock?.name,
          { totalBlocks: blockCount + 1, blockIndex: blockCount, requestSent },
          lastBlock?.config,
          blockCount
        );
    }
  }, [editorRef, requestSent]);

  return (
    <div className={nested ? undefined : 'p-2'}>
      <div
        id={editorblock}
        className={`editor-container ml-auto ${nested ? '' : 'prose'} ${readOnly ? 'read-only' : ''}`}
      />
      {!disabled && !readOnly && !previewing && (
        <Button
          icon={<PlusIcon height={26} width={26} className="my-auto rounded-md bg-green-400 text-white mr-auto" />}
          className="ce-toolbar__button !p-0 !text-green-400 hover:bg-opacity-60 ml-1 max-w-32 !w-full"
          onClick={onQuickCreate}
          variant="custom"
          text={quickCreateTitle}
        />
      )}
    </div>
  );
};

export const FormEditor = memo(Editor);
