import { useAuth0 } from '@auth0/auth0-react';
import { ForceRegisterPrompts, ICompanyValidationProps } from './force-register-prompts.component';
import { useCurrCompany } from '../../../domains/companies/company.service';
import { useFetchCurrUser } from '../../../domains/users/user.service';

export const ForceRegisterPromptsContainer = (props: ICompanyValidationProps) => {
  const { user: authUser } = useAuth0();

  const { company, loading: loadingCompany, called: fetchedCurrCompany } = useCurrCompany();
  const { user, loading: loadingUser } = useFetchCurrUser();

  if (!authUser || (loadingUser && !user)) return null;

  const isRegistered = !!user;
  return (
    <ForceRegisterPrompts
      authUser={authUser}
      companiesLoading={!fetchedCurrCompany || loadingCompany}
      company={company}
      isRegistered={isRegistered}
      {...props}
    />
  );
};
